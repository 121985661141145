<template>
  <div>
    <!--**********************************
    Main wrapper start
    ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
        Content body start
    ***********************************-->
      <div class="content-body pt-0 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">
              {{ lan.admin[4].Opportunity_page[0].Edit_Opportunity }}
            </h2>
          </div>
          
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header pb-2 d-block flex-wrap border-0">
                  <div class="d-flex mt-3 justify-content-between">
                    <div class="buttons">
                      <button
                        class="filter-btn up-btn ml-0"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        {{ lan.admin[4].Opportunity_page[0].Add_new_note }}
                      </button>
                      <button
                        class="filter-btn up-btn"
                        data-toggle="modal"
                        data-target="#exampleModal1"
                      >
                        {{ lan.admin[4].Opportunity_page[0].Add_new_task }}
                      </button>
                    </div>
                    <div class="three-dots">
                      <div class="dropdown">
                        <button
                          class="dot-btn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            class="fa-solid fa-ellipsis-vertical pointer"
                            style="font-size: 20px"
                          ></i>
                        </button>
                        <div class="dropdown-menu">
                          <a
                            class="dropdown-item pointer"
                            data-toggle="modal"
                            @click="openModal('lost')"
                            >{{
                              lan.admin[4].Opportunity_page[0]
                                .Cancel_Opportunity
                            }}</a
                          >
                          <a
                          v-if="status !== 'Won'"
                            class="dropdown-item pointer"
                            data-toggle="modal"
                            @click="openModal('won')"
                            >{{ lan.admin[4].Opportunity_page[0].Won }}</a
                          >
                          <a v-if="status == 'Won'"
                            class="dropdown-item pointer"
                            data-toggle="modal"
                            @click="openModal('resend-booking')"
                            >{{ lan.admin[4].Opportunity_page[0].Send_booking_request }}</a
                          >
                          <!-- <a class="dropdown-item" href="#">Something else here</a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row noteTaskRow">
                    <div class="col-lg-6" v-if="note_detail && note_detail.length">
                      <h5 class="mb-0 py-2 text-center bgLinear">{{ lan.admin[4].Opportunity_page[0].Notes }}</h5>
                      <table
                        class="pointer"
                      >
                        <tr
                          v-for="(data, index) in note_detail"
                          :key="index"
                          @click="editNote(data.note_id)"
                        >
                          <td>
                            <strong>{{ index + 1 }}</strong>
                          </td>
                          <td>
                            <p class="mb-0">{{ data.subject }}</p>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-6" v-if="task_detail && task_detail.length">
                      <h5 class="mb-0 py-2 text-center bgLinear">{{ lan.admin[4].Opportunity_page[0].Tasks }}</h5>
                      <table
                        class="pointer"
                      >
                        <tr
                          v-for="(data, index) in task_detail"
                          :key="index"
                          @click="editTask(data.task_id)"
                        >
                          <td>
                            <strong>{{ index + 1 }}</strong>
                          </td>
                          <td>
                            <p class="mb-0">{{ data.subject }}</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="row px-3">
                    <table class="col-md-6 bg-info-hover tr-rounded order-tbl">
                      <tbody>
                        <tr>
                          <td>
                            {{
                              lan.admin[4].Opportunity_page[0].Opportunity_owner
                            }}
                          </td>
                          <td v-if="opportunity_owner_details">
                            <input
                              type="text"
                              name="other date"
                              class="init"
                              :placeholder="opportunity_owner_details.first_name + ' ' + opportunity_owner_details.last_name"
                              id=""
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                  <div class="mb-3 mt-4">
                    <h4 class="fs-20 text-black">
                      {{ lan.admin[4].Opportunity_page[0].Opportunity }}
                    </h4>
                  </div>

                  <div class="card-body tab-content p-0">
                    <div class="tab-pane active show fade" id="monthly">
                      <!-- <div class="d-flex mb-4">
                        <select
                          name=""
                          class="select-01"
                          style="width: 30%; height: 43px"
                          id=""
                        >
                          <option value="">Contact</option>
                          <option value="">Contact 1</option>
                          <option value="">Contact 2</option>
                        </select>

                        <a href="contact-form.html"
                          ><button
                            class="filter-btn mb-0"
                            style="margin-left: 100px"
                          >
                            Create new contact
                          </button></a
                        >
                      </div> -->

                      <div class="table-responsive">
                        <table class="bg-info-hover tr-rounded order-tbl">
                          <tbody>
                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Our_customer
                                }}
                              </td>
                              <td
                                v-if="self == 0"
                                class="d-flex justify-content-between"
                              >
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  v-model="selectAccountDetailsName"
                                  placeholder="Our customer"
                                  id=""
                                />
                                <!-- {{ selectAccountDetails == '' ? 'Account' : selectAccountDetails.name }} -->
                                <!-- <select
                                  name=""
                                  class="select-01"
                                  style="width: 30%; height: 43px"
                                  id=""
                                >
                                  <option value="">Account</option>
                                  <option value="">Account 1</option>
                                  <option value="">Account 2</option>
                                  <option value="">Account 3</option>
                                </select> -->

                                <!-- <button class="filter-btn mb-0">
                                  Create new account
                                </button> -->
                              </td>
                              <td v-if="self == 1">
                                <select
                                  class="init"
                                  style="width: 30%; height: 43px"
                                  @change="checkContact(this.account_id)"
                                  v-model="account_id"
                                >
                                  <option value="" disabled selected>
                                    {{lan.admin[4].Opportunity_page[0].Select_Account}}
                                  </option>
                                  <option
                                    v-for="(
                                      data, index
                                    ) in Customer_AccountDetails"
                                    :key="index"
                                    :value="data.account_id"
                                  >
                                    {{ data.name }}
                                  </option>
                                </select>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Customer_name
                                }}
                              </td>
                              <td v-if="self == 0">
                                <input
                                  type="text"
                                  name="customer_name"
                                  class="init"
                                  v-model="customer_name"
                                  id=""
                                />
                              </td>
                              <td v-if="self == 1">
                                <select
                                  class="select"
                                  style="width: 30%; height: 43px"
                                  v-model="contact_id"
                                >
                                  <option value="" disabled selected>
                                    {{lan.admin[4].Opportunity_page[0].Select_Contact}}
                                  </option>
                                  <option
                                    :value="data.id"
                                    v-for="(
                                      data, index
                                    ) in contact_details_by_account_id"
                                    :key="index.id"
                                  >
                                    {{ data.first_name }} {{ data.last_name }}
                                  </option>
                                </select>
                              </td>
                            </tr>
                            <!-- <tr v-if="self == 1" >
                              <td>Email</td>
                              <td>
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  v-model="customer_email"
                                  placeholder="Enter email ID to invite cutomer"
                                  id=""
                                />
                              </td>
                            </tr> -->

                            <tr>
                              <td>
                                {{ lan.admin[4].Opportunity_page[0].Date }}
                              </td>
                              <td>
                                <input
                                  class="init mr-3"
                                  style="width: 25%;"
                                  type="date"
                                  name="other date"
                                  v-model="date_of_conference_start"
                                  id=""
                                />

                                <span>To</span>
                                <input
                                  class="init ml-3"
                                  style="width: 25%;"
                                  type="date"
                                  name="other date"
                                  v-model="date_of_conference_end"
                                  id=""
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  v-model="number_of_participants"
                                  min="0"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Type_Of_Conference
                                }}
                              </td>
                              <td>
                                <select
                                  class="init"
                                  style="width: 40%; height: 43px"
                                  v-model="type_of_conference"
                                >
                                  <!-- <option value="" selected>
                                    Select Type Of Conference
                                  </option> -->
                                  <option
                                    value="Conference with overnight stay"
                                  >
                                  {{lan.admin[4].Opportunity_page[0].Conference_with_overnight_stay}}
                                  </option>
                                  <option value="Day conference">
                                    {{lan.admin[4].Opportunity_page[0].Day_conference}}
                                  </option>
                                </select>
                              </td>
                            </tr>

                            <tr v-if="self == 0">
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Accomodations
                                }}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  style="width: 40%; height: 43px"
                                  v-model="accomodations"
                                  placeholder="Accomodations"
                                  id=""
                                />
                              </td>
                            </tr>
                            <tr
                              v-if="
                                self == 1 &&
                                type_of_conference ==
                                  'Conference with overnight stay'
                              "
                            >
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Accomodations
                                }}
                              </td>
                              <td>
                                <select
                                  class="init"
                                  style="width: 40%; height: 43px"
                                  v-model="accomodations"
                                >
                                  <!-- <option value="" disabled selected>
                                    Select Accomodation
                                  </option> -->
                                  <option
                                    :value="data.product_name"
                                    v-for="(
                                      data, index
                                    ) in productOnlyAcomdation"
                                    :key="index"
                                  >
                                    {{ data.product_name }}
                                  </option>
                                </select>
                                <!-- <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  v-model="
                                    number_of_participants_in_single_room
                                  "
                                  placeholder="Accomodations"
                                  id=""
                                /> -->
                              </td>
                            </tr>
                            <tr v-if="check_acc_Single_double == true">
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants_in_single_room
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="init"
                                  v-model="
                                    number_of_participants_in_single_room
                                  "
                                  min="0"
                                  style="width: 20%; height: 43px"
                                />
                              </td>
                            </tr>
                            <tr v-if="check_acc_Single_double == true">
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants_in_double_room
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="init"
                                  v-model="no_of_people_in_shared_double_rooms"
                                  min="0"
                                  style="width: 20%; height: 43px"
                                />
                              </td>
                            </tr>

                            <!-- <tr v-if="check_accomodations && self == 0">
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants_in_single_room
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="init"
                                  v-model="
                                    number_of_participants_in_single_room
                                  "
                                  placeholder="Number of participants in single room"
                                  min="0"
                                  style="width: 40%; height: 43px"
                                />
                              </td>
                            </tr> -->
                            <!-- <tr v-if="check_accomodations && self == 0">
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants_in_double_room
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="init"
                                  v-model="no_of_people_in_shared_double_rooms"
                                  placeholder="Number of participants in double room"
                                  min="0"
                                  style="width: 40%; height: 43px"
                                />
                              </td>
                            </tr> -->

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Arrival_time
                                }}
                              </td>
                              <td>
                                <input
                                  type="time"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  v-model="arrival_time"
                                  id=""
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Departure_time
                                }}
                              </td>
                              <td>
                                <input
                                  type="time"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  v-model="departure_time"
                                  id=""
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Requested_size_of_the_conference_room
                                }}
                              </td>

                              <td>
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  placeholder="Please enter"
                                  v-model="no_of_participants_in_conf"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                        <form action="">
                          <div class="col-md-6 px-0 mt-4">
                            <label
                              >{{
                                lan.admin[4].Opportunity_page[0]
                                  .Prices_should_be
                              }}<span>*</span></label
                            ><br />
                            <input
                              type="radio"
                              id="html"
                              name="fav_language"
                              value="excl vat"
                              v-model="vat"
                            />
                            &nbsp;
                            <label for="html">{{
                              lan.admin[4].Opportunity_page[0].excl_VAT
                            }}</label>
                            &nbsp;
                            <input
                              type="radio"
                              id="css"
                              name="fav_language"
                              value="incl vat"
                              v-model="vat"
                            />
                            &nbsp;
                            <label for="css">{{
                              lan.admin[4].Opportunity_page[0].inc_VAT
                            }}</label>
                          </div>
                          <textarea
                            name=""
                            id=""
                            cols="20"
                            rows="5"
                            style="width: 50%"
                            class="mt-4"
                            v-model="free_text_message"
                            placeholder="Comments"
                          ></textarea>

                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0].Needs_analysis
                              }}
                            </h4>
                          </div>

                          <textarea
                            name=""
                            id=""
                            cols="20"
                            rows="10"
                            class="mt-4"
                            v-model="opportunity_comments"
                          >
                           Comments</textarea
                          >
                        </form>

                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.admin[4].Opportunity_page[0]
                                .Common_questions_to_ask_customer
                            }}
                          </h4>
                        </div>
                        <!--for hide and show accrodian start-->
                        <div
                          @click="hideOpenAccordian()"
                          class="d-flex justify-content-between px-2 py-3 mb-4 mainAccordian"
                        >
                          <div>
                            <h5 class="mb-0">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Open_All_Questions
                              }}
                            </h5>
                          </div>
                          <div>
                            <i class="fa-solid fa-caret-down topCaret"></i>
                          </div>
                        </div>
                        <!--for hide and show accrodian end-->

                        <div v-show="hideAccordian" id="accordion">
                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingOne"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Are_the_dates_set_or_can_you_consider_other_alternative_dates
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>

                            <div
                              id="collapseOne"
                              class="collapse"
                              aria-labelledby="headingOne"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Alternative_dates
                                  "
                                  id=""
                                  v-model="addCommonQuestion[0].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingTwo"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .How_many_participants_are_you
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseTwo"
                              class="collapse"
                              aria-labelledby="headingTwo"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[1].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingThree"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Can_you_give_me_an_estimated_plan_for_your_conference
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseThree"
                              class="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Alternative_dates
                                  "
                                  id=""
                                  v-model="addCommonQuestion[2].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFour"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Day_conference_or_overnight_stay
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFour"
                              class="collapse"
                              aria-labelledby="headingFive"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Alternative_dates
                                  "
                                  id=""
                                  v-model="addCommonQuestion[3].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFive"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0].full_days
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFive"
                              class="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[4].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFive"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .In_which_region_are_you_planning_to_conduct_your_conference
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFive"
                              class="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[5].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSix"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSix"
                                  aria-expanded="false"
                                  aria-controls="collapseSix"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Where_are_the_participants_coming_from
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSix"
                              class="collapse"
                              aria-labelledby="headingSix"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[6].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSeven"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSeven"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Are_any_participants_arriving_by_airplane_Is_it_important_to_be_close_to_an_airport
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSeven"
                              class="collapse"
                              aria-labelledby="headingSeven"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[7].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEight"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseEight"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Where_is_your_office_located
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseEight"
                              class="collapse"
                              aria-labelledby="headingEight"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[8].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingNine"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseNine"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Where_is_you_bossgroup_leader_located
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseNine"
                              class="collapse"
                              aria-labelledby="headingNine"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[9].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingTen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseTen"
                                  aria-expanded="false"
                                  aria-controls="collapseTen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .What_type_of_conference_venue_do_you_have_in_mind
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseTen"
                              class="collapse"
                              aria-labelledby="headingTen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init mt-2"
                                  v-model="addCommonQuestion[10].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEleven"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseEleven"
                                  aria-expanded="false"
                                  aria-controls="collapseEleven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Is_this_a_recurring_eventconference_Where_have_you_been_before
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseEleven"
                              class="collapse"
                              aria-labelledby="headingEleven"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[11].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingTwelve"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseTwelve"
                                  aria-expanded="false"
                                  aria-controls="collapseTwelve"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Whats_the_purpose_of_this_conference_What_is_important_for_you
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseTwelve"
                              class="collapse"
                              aria-labelledby="headingTwelve"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[12].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingThirteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseThirteen"
                                  aria-expanded="false"
                                  aria-controls="collapseThirteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .If_the_venue_cant_offer_you_single_room_could_you_consider_sharing_double_rooms
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseThirteen"
                              class="collapse"
                              aria-labelledby="headingThirteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[13].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingForteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseForteen"
                                  aria-expanded="false"
                                  aria-controls="collapseForteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Do_you_need_rooms_for_break_out_sessions
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseForteen"
                              class="collapse"
                              aria-labelledby="headingForteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[14].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFifteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFifteen"
                                  aria-expanded="false"
                                  aria-controls="collapseFifteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Are_you_looking_for_some_sort_of_team_building_activity
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFifteen"
                              class="collapse"
                              aria-labelledby="headingFifteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[15].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSixteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSixteen"
                                  aria-expanded="false"
                                  aria-controls="collapseSixteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .If_yes_what_kind_Physical_demanding_or_mentally_demanding
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSixteen"
                              class="collapse"
                              aria-labelledby="headingSixteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[16].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSeveteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSeveteen"
                                  aria-expanded="false"
                                  aria-controls="collapseSeveteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Whats_the_setup_of_the_group_Age_gender
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSeveteen"
                              class="collapse"
                              aria-labelledby="headingSeveteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[17].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEightteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseEightteen"
                                  aria-expanded="false"
                                  aria-controls="collapseEightteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Do_you_have_a_budget_for_the_event
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseEightteen"
                              class="collapse"
                              aria-labelledby="headingEightteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[18].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEightteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseNineteen"
                                  aria-expanded="false"
                                  aria-controls="collapseNineteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .By_when_do_you_need_the_quote
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseNineteen"
                              class="collapse"
                              aria-labelledby="headingNineteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[19].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="type_of_conference == 'Conference with overnight stay'" >
                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.admin[4].Opportunity_page[0]
                                .Add_accommodation
                            }}
                          </h4>
                        </div>

                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <th style="width: 10%;">
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th style="width: 10%;">
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Number_of_participants
                                  }}
                                </th>
                                <th class="border-0"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addAcomdation"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'accomodation')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select
                                    name=""
                                    id=""
                                    class="select"
                                    v-model="data.product_id"
                                    @change="updateProduct(data, 'Accomodation')"
                                  >
                                  <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(
                                        product, index
                                      ) in productOnlyAcomdation"
                                      :key="index"
                                      :value="product.product_id"
                                    >
                                      {{ product.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    v-model="data.date"
                                    placeholder="Our customer"
                                    id=""
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    name="other date"
                                    class="init"
                                    v-model="data.room"
                                    placeholder="NUMBER FROM LEAD"
                                    id=""
                                  />
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Accomodation')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                         </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('Accomodation')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>
                        </div>

                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{ lan.admin[4].Opportunity_page[0].Add_meals }}
                          </h4>
                        </div>

                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <th style="width: 10%;">
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th style="width: 10%;">
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Number_of_participants
                                  }}
                                </th>
                                <th class="border-0"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addMeals"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'meals')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select
                                    name=""
                                    id=""
                                    class="select"
                                    v-model="data.product_id"
                                    @change="updateProduct(data, 'Meals')"
                                  >
                                  <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(data, index) in productOnlyMeals"
                                      :key="index"
                                      :value="data.product_id"
                                    >
                                      {{ data.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    v-model="data.date"
                                    placeholder="Our customer"
                                    id=""
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    name="other date"
                                    class="init"
                                    v-model="data.room"
                                    placeholder="NUMBER FROM LEAD"
                                    id=""
                                  />
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Meals')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                         </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('Meals')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>

                          

                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Add_other_products
                              }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <!-- <th class="comment-01">
                                  {{ lan.admin[4].Opportunity_page[0].Comment }}
                                </th> -->
                                <th style="width: 10%;">
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Number_of_participants
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addOtherProduct"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'other')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select class="select" v-model="data.product_id" @change="updateProduct(data, 'OtherProduct')">
                                    <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(data, index) in productOnlyOthers"
                                      :key="index"
                                      :value="data.product_id"
                                    >
                                      {{ data.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <!-- <td class="b-buttom">
                                  <input
                                    type="text"
                                    class="init"
                                    v-model="data.comment"
                                  />
                                </td> -->
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    placeholder="Our customer"
                                    v-model="data.date"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    name="other date"
                                    class="init"
                                    v-model="data.room"
                                    placeholder="NUMBER FROM LEAD"
                                    id=""
                                  />
                                </td>

                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'OtherProduct')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('OtherProduct')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>


                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.admin[4].Opportunity_page[0]
                                .Add_Conference_room
                            }}
                          </h4>
                        </div>

                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <th class="comment">
                                  {{ lan.admin[4].Opportunity_page[0].Comment }}
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Requested_size
                                  }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Seating_type
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addConferenceRoom"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'conference')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select
                                    name=""
                                    id=""
                                    class="select"
                                    v-model="data.product_id"
                                    @change="updateProduct(data, 'Conference')"
                                  >
                                  <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(
                                        data, index
                                      ) in productOnlyConferenceRoom"
                                      :key="index"
                                      :value="data.product_id"
                                    >
                                      {{ data.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    id="html"
                                    class="init"
                                    name="fav_language"
                                    v-model="data.comment"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    placeholder="Our customer"
                                    id=""
                                    v-model="data.date"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    id="html"
                                    class="init"
                                    name="fav_language"
                                    placeholder="Number form lead"
                                    v-model="data.request_size"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <select class="select" v-model="data.seating">
                                    <option value="Cinema Seating">
                                      {{lan.admin[4].Opportunity_page[0].Cinema_Seating}}
                                    </option>
                                    <option value="U-shaped table">
                                      {{lan.admin[4].Opportunity_page[0].Ushaped_table}}
                                    </option>
                                    <option value="Boardroom">{{lan.admin[4].Opportunity_page[0].Boardroom}}</option>
                                    <option value="School Seating">
                                      {{lan.admin[4].Opportunity_page[0].School_Seating}}
                                    </option>
                                    <option value="Islands">{{lan.admin[4].Opportunity_page[0].Islands}}</option>
                                    <option value="Don't know at the moment">
                                      {{lan.admin[4].Opportunity_page[0].Dont_know_at_the_moment}}
                                    </option>
                                  </select>
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Product')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('Product')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>


                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Add_your_question_to_partners
                              }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                            <table>
                              <tr>
                                <td>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Write_your_question
                                  }}
                                </td>
                                <td>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Select_question_type
                                  }}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    id="html"
                                    class="init"
                                    name="fav_language"
                                    v-model="add_question.question"
                                  />
                                </td>
                                <td>
                                  <select
                                    class="select-01 init-01"
                                    v-model="add_question.type"
                                  >
                                    <option value="dropdown">Dropdown</option>
                                    <option value="text">Text</option>
                                    <option value="radio">Radio</option>
                                  </select>
                                  <button @click="addQuestion()" class="ml-3 add-btn" :disabled="!add_question.question" >
                                    {{ lan.admin[4].Opportunity_page[0].Add }}
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div
                            class="mb-3 mt-4"
                            v-if="
                              additional_questions &&
                              additional_questions.length
                            "
                          >
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Questions_to_partners
                              }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                            <table
                              style="white-space: normal"
                              class="border-0"
                              v-for="(data, index) in additional_questions"
                              :key="index"
                            >
                              <div class="d-flex question_to_partner">
                                <div class="drag">
                                  <td
                                    draggable="true"
                                    @dragstart="dragStart(index)"
                                    @dragover="dragOver"
                                    @drop="drop(index, 'question')"
                                  >
                                    <img
                                      src="../../../../public/images/drag.png"
                                      alt=""
                                      style="height: 25px; width: 25px"
                                    />
                                  </td>
                                </div>

                                <div class="texts" style="width: 100%">
                                  <tr>
                                    <td class="b-left">{{ data.question }}</td>
                                  </tr>

                                  <tr v-if="data.inputType == 'text'">
                                    <td
                                      class="b-left b-buttom"
                                      style="width: 100%"
                                    >
                                      <input
                                        type="text"
                                        name="other date"
                                        class="init"
                                        id=""
                                        v-model="data.answer"
                                      />
                                    </td>


                                    <!-- <td class="border-0">
                                      <input
                                        type="checkbox"
                                        id="vehicle1"
                                        value="Bike"
                                        v-model="data.isShowOrNot"
                                      />
                                    </td> -->

                                    <td class="border-0">
                                      <input
                                        type="checkbox"
                                        style="margin-bottom: 25px"
                                        id="vehicle1"
                                        value="Bike"
                                        v-model="data.isShowOrNot"
                                      />
                                      <button
                                        class="btn btn-danger shadow btn-xs sharp"
                                        @click="deleteQuestion(index)"
                                      >
                                        <i class="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>


                                  <tr v-else-if="data.inputType == 'dropdown'">
                                    <td
                                      class="b-left b-buttom"
                                      style="width: 100%"
                                    >
                                      <select
                                        class="select"
                                        v-model="data.answer"
                                        @change="selectDropdown(index)"
                                      >
                                        <option
                                          v-if="data.dropdown.length == 0"
                                          disabled
                                        >
                                          Empty
                                        </option>
                                        <option
                                          :value="indexx"
                                          v-for="(
                                            dataa, indexx
                                          ) in data.dropdown"
                                          :key="indexx"
                                        >
                                          {{ dataa.name }}
                                        </option>
                                      </select>
                                      <div
                                        v-if="data.dropdown.length"
                                        class="tag-container"
                                      >
                                       <h5>{{ lan.admin[4].Opportunity_page[0].Options }}:- </h5>
                                        <span
                                          v-for="item in data.dropdown"
                                          :key="item"
                                          class="tag"
                                        >
                                          {{ item.name }}
                                          <i
                                            class="fa fa-times"
                                            @click="
                                              optionsDropdownDelete(item, index)
                                            "
                                          ></i>
                                        </span>
                                      </div>
                                      <div class="mt-3 row align-items-end">
                                        <div class="col-6">
                                          <div
                                            v-if="data.answertags.length"
                                            class="tag-container"
                                          >
                                           <h5 class="mr-2">{{ lan.admin[4].Opportunity_page[0].Selected }}:- </h5>
                                            <span
                                              v-for="item in data.answertags"
                                              :key="item"
                                              class="tag"
                                            >
                                              {{ item.name }}
                                              <i
                                                class="fa fa-times"
                                                @click="
                                                  selectDropdownDelete(
                                                    item,
                                                    index
                                                  )
                                                "
                                              ></i>
                                            </span>
                                          </div>
                                          <input
                                            class="init"
                                            style="width: 60%"
                                            type="text"
                                            v-model="dropdown_option"
                                          />
                                          <button
                                            class="ml-3 mt-md-0 mt-2"
                                            @click="addDropdown(index)"
                                          >
                                           {{ lan.admin[4].Opportunity_page[0].Add }}
                                          </button>
                                        </div>
                                        <div class="col-6">
                                          <label class="d-block">{{ lan.admin[4].Opportunity_page[0].Set_Maximum_select }}</label
                                          >
                                          <input
                                            class="init init-01"
                                            type="number"
                                            v-model="data.maxSelect"
                                            :placeholder="lan.admin[4].Opportunity_page[0].Set_Maximum_select"
                                          />
                                        </div>
                                      </div>
                                    </td>


                                    <!-- <td >
                                      <input
                                        type="checkbox"
                                        id="vehicle1"
                                        value="Bike"
                                        v-model="data.isShowOrNot"
                                      />
                                    </td> -->

                                    <td class="border-0">
                                      <input
                                        type="checkbox"
                                        style="margin-bottom: 25px"
                                        id="vehicle1"
                                        value="Bike"
                                        v-model="data.isShowOrNot"
                                      />
                                      <button
                                        class="btn btn-danger shadow btn-xs sharp"
                                        @click="deleteQuestion(index)"
                                      >
                                        <i class="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>

                                  <tr v-else-if="data.inputType == 'radio'">
                                    <td
                                      class="b-left b-buttom"
                                      style="
                                        width: 100%;
                                        text-align: end;
                                        padding-right: 20px;
                                      "
                                    >
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="html"
                                        name="fav_language"
                                        value="Yes"
                                        v-model="data.answer"
                                      />
                                      &nbsp;
                                      <label for="Yes">Yes</label>
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="css"
                                        name="fav_language"
                                        value="No"
                                        v-model="data.answer"
                                      />
                                      &nbsp;
                                      <label for="No">No</label>
                                    </td>

                                    <td class="border-0"></td>

                                    <!-- <td >
                                      <input
                                        type="checkbox"
                                        id="vehicle1"
                                        value="Bike"
                                        v-model="data.isShowOrNot"
                                      />
                                    </td> -->

                                    <td class="border-0">
                                      <input
                                        type="checkbox"
                                        style="margin-bottom: 25px"
                                        id="vehicle1"
                                        value="Bike"
                                        v-model="data.isShowOrNot"
                                      />
                                      <button
                                        class="btn btn-danger shadow btn-xs sharp"
                                        @click="deleteQuestion(index)"
                                      >
                                        <i class="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </div>
                              </div>
                            </table>
                          </div>

                          <!-- <div class="input d-flex mb-4">
                            <div class="devide-01">
                              <label>New note<span>*</span></label
                              ><br />
                              <div class="container22">
                                <table>
                                  <tr
                                    v-for="(data, index) in allNoteData"
                                    :key="index"
                                  >
                                    <td>
                                      <input
                                        type="checkbox"
                                        v-model="noteCheckbox"
                                        :value="data.note_id"
                                      />
                                    </td>
                                    <td>{{ data.subject }}</td>
                                    
                                  </tr>
                                </table>
                              </div>
                            </div>

                            <div class="devide-01 ml-3">
                              <label>New task<span>*</span></label
                              ><br />
                              <div class="container22">
                                <table>
                                  <tr
                                    v-for="(data, index) in allTaskData"
                                    :key="index"
                                  >
                                    <td>
                                      <input
                                        type="checkbox"
                                        v-model="taskCheckbox"
                                        :value="data.task_id"
                                      />
                                    </td>
                                    <td>{{ data.subject }}</td>
                                    
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div> -->

                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{ lan.admin[4].Opportunity_page[0].Partner }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Partner_venue_name
                                  }}
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Status }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Send_invitation
                                  }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Resend_invitation
                                  }}
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Quote }}
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Booking }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Present_to_customer
                                  }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Action
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="addBidders && addBidders.length">
                              <tr
                                v-for="(data, index) in addBidders"
                                :key="index"
                              >
                              <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'bidder')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left">
                                  <select
                                    class="select-01"
                                    v-model="data.account_id"
                                  >
                                    <option
                                      v-for="(data1, index) in filter_bidder"
                                      :key="index"
                                      :value="data1.account_id"
                                      :disabled="
                                        data.status == 'Invitation not sent' &&
                                        !data1.disabled
                                          ? false
                                          : data.status != 'Invitation not sent'
                                          ? true
                                          : data1.disabled
                                      "
                                      :class="
                                        data1.disabled == false
                                          ? 'dropdown-color-green'
                                          : 'dropdown-color-red'
                                      "
                                    >
                                    {{ data1.is_outside_partner != 1 ? data1.venue_name ? data1.venue_name : data1.name : data1.venue_name ? data1.venue_name : data1.name ? data1.name : data1.email}} 
                                    {{ data1.is_outside_partner == 1 || data1.is_outside_partner == '1' ? '&#127358' : ''}}
                                    <!-- {{ data1.account_id }} -->
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <span class="badge" :class="data.status == 'Booked by Customer' ? 'badge-success' : ''" >{{data.status}}</span>
                                </td>
                                <td>
                                  <span
                                    class="badge badge-success pointer"
                                    v-show="loader_Loading_T[index].send"
                                    data-target="#exampleModal-676"
                                    data-toggle="modal"
                                    @click="invetation_Set_RawData(data, index, 'Send')"
                                    >{{
                                      data.send_invitation == 1 ? lan.admin[4].Opportunity_page[0].Send : ""
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C[index].send"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </td>
                                <td>
                                  <span
                                    class="badge badge-danger pointer"
                                    v-show="loader_Loading_T[index].resend"
                                    data-target="#exampleModal-676"
                                    data-toggle="modal"
                                    @click="
                                      invetation_Set_RawData(data, index, 'Resend')
                                    "
                                    >{{
                                      data.resend_invitation == 1
                                        ? lan.admin[4].Opportunity_page[0].Resend
                                        : ""
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C[index].resend"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    v-if="
                                      data.status != 'Invitation Sent' &&
                                      data.status != 'Invitation not sent'
                                    "
                                    @click="edit(data.quote_id)"
                                  >
                                   {{ lan.admin[4].Opportunity_page[0].Open_quote }}
                                  </button>
                                </td>
                                <td>
                                  <span
                                    v-if="data.status == 'Quote Submitted' || data.status == 'Preliminary Booking'"
                                    class="badge pointer"
                                    :class="
                                      data.preliminary == 0 ||
                                      data.preliminary == '0'
                                        ? 'badge-success'
                                        : 'badge-danger'
                                    "
                                    v-show="loader_Loading_T[index].booking"
                                    @click="
                                      preliminaryBooking(data, index, 'Resend')
                                    "
                                    >{{
                                      data.preliminary == 0 ||
                                      data.preliminary == "0"
                                        ? "Send"
                                        : "Resend"
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C[index].booking"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>

                                  <a 
                                    v-if="data.preliminary == 1 || data.preliminary == '1'"
                                    href="#invitationRevertModal"
                                    class="trigger-btn ml-2"
                                    data-toggle="modal"
                                    @click="Revert(index, data, 'revert-status/preliminary-booking')"
                                  >
                                    <span
                                      class="btn btn-m sharp"
                                      title="Revert Preliminary Booking Status"
                                    >
                                     <i class="fa-solid fa-clock-rotate-left text-success"></i>
                                    </span>
                                    <!-- <div
                                      v-show="loader_Loading_C[index].delete"
                                      class="loadingio-spinner-rolling-yxlbb0cd94"
                                    >
                                      <div class="ldio-4c1f6xkxs72">
                                        <div></div>
                                      </div>
                                    </div> -->
                                  </a>

                                  <a 
                                    v-if="data.preliminary == 1 || data.preliminary == '1'"
                                    class="trigger-btn ml-2 pointer"
                                    @click="check_preliminary_status(index, data,)"
                                  >
                                    <span
                                      class="btn btn-m sharp"
                                      data-bs-toggle="tooltip"
                                      :title="data.tooltip_title"
                                    >
                                      <i v-if="!loader_Loading_C[index].check_booking_status"
                                       class="fa-solid fa-inbox text-success"></i>
                                       <div
                                          v-if="loader_Loading_C[index].check_booking_status"
                                          class="loadingio-spinner-rolling-yxlbb0cd94"
                                        >
                                          <div class="ldio-4c1f6xkxs72">
                                          <div></div>
                                          </div>
                                        </div>
                                    </span>
                                    <!-- <div
                                      v-show="loader_Loading_C[index].delete"
                                      class="loadingio-spinner-rolling-yxlbb0cd94"
                                    >
                                      <div class="ldio-4c1f6xkxs72">
                                        <div></div>
                                      </div>
                                    </div> -->
                                  </a>
                                  

                                </td>
                                <td style="position: relative">
                                  <input
                                    v-if="
                                      data.status != 'Invitation Sent' &&
                                      data.status != 'Quote Accepted' &&
                                      data.status != 'Quote Denied' &&
                                      data.status != 'Invitation not sent'
                                    "
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehi:grinning:cle1"
                                    value="Bike"
                                    v-model="data.present_to_customer"
                                  />
                                  <span
                                    v-if="
                                      data.invited == '1' || data.invited == 1
                                    "
                                    class="tick_icon"
                                  >&#10004
                                  </span>
                                </td>
                                <td class="border-0">
                                  <button
                                    v-if="data.status == 'Invitation not sent'"
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Bidder', data)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                  <a
                                    v-else
                                    href="#myModal"
                                    class="trigger-btn"
                                    data-toggle="modal"
                                    @click="deleteRow(index, 'Bidder', data)"
                                  >
                                    <button
                                      v-show="loader_Loading_T[index].delete"
                                      class="btn btn-danger shadow btn-xs sharp"
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                    <div
                                      v-show="loader_Loading_C[index].delete"
                                      class="loadingio-spinner-rolling-yxlbb0cd94"
                                    >
                                      <div class="ldio-4c1f6xkxs72">
                                        <div></div>
                                      </div>
                                    </div>
                                  </a>
                                <!-- </td>
                                <td class="border-0"> -->
                                  <a
                                    href="#invitationRevertModal"
                                    class="trigger-btn ml-2 pointer"
                                    data-toggle="modal"
                                    @click="Revert(index, data, 'revert-status')"
                                  >
                                    <span
                                      class="btn btn-m sharp"
                                      title="Revert bidder status"
                                    >
                                    <i class="fa-solid fa-clock-rotate-left text-success"></i>
                                    </span>
                                    <!-- <div
                                      v-show="loader_Loading_C[index].delete"
                                      class="loadingio-spinner-rolling-yxlbb0cd94"
                                    >
                                      <div class="ldio-4c1f6xkxs72">
                                        <div></div>
                                      </div>
                                    </div> -->
                                  </a>
                                  
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <!-- <div class="table-responsive">
                          <table class="text-center bg-info-hover tr-rounded order-tbl mt-5">
                            <thead>
                              <tr>
                                <th>
                                  Partner Email id
                                </th>
                                <th>
                                  Partner Name
                                </th>
                                <th>
                                  Status
                                </th>
                                <th>
                                  Invitation
                                </th>
                                <th>
                                 Quote
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Booking }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Present_to_customer
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in addBidders_Outside" :key="index" >
                                <td>
                                  <input class="select-01" type="text" v-model="data.email" placeholder="Enter Email id" :disabled="data.status == 'Invitation not sent' ? false : true">
                                </td>
                                <td>
                                  <input class="select-01" type="text" v-model="data.name" placeholder="Enter Name" :disabled="data.status == 'Invitation not sent' ? false : true">
                                </td>
                                <td>
                                  {{ data.status }}
                                </td>
                                <td>
                                  <span
                                    class="badge badge-success pointer"
                                    :class="data.send_invitation == 1 ? 'badge-success' : 'badge-danger'"
                                    v-show="loader_Loading_T_2[index].send"
                                    @click="invetationSend_outside_partner(data, index, data.send_invitation)"
                                    >{{
                                      data.send_invitation == 1 ? "Send" : "Resend"
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C_2[index].send"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    v-if="data.status != 'Invitation Sent' && data.status != 'Invitation not sent'"
                                    @click="edit(data.quote_id)"
                                  >
                                    Open quote
                                  </button>
                                </td>
                                <td>
                                  <span
                                    v-if="data.status == 'Quote Submitted'"
                                    class="badge pointer"
                                    :class="
                                      data.preliminary == 0 ||
                                      data.preliminary == '0'
                                        ? 'badge-success'
                                        : 'badge-danger'
                                    "
                                    v-show="loader_Loading_T[index].booking"
                                    @click="
                                      preliminaryBooking(data, index, 'Resend')
                                    "
                                    >{{
                                      data.preliminary == 0 ||
                                      data.preliminary == "0"
                                        ? "Send"
                                        : "Resend"
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C[index].booking"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                    v-model="data.present_to_customer"
                                    :disabled="
                                      data.status == 'Quote Submitted'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td class="border-0">
                                  <button
                                  v-if="data.status == 'Invitation not sent'"
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Bidder_Outside')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                  <a
                                    v-else
                                    href="#myModal"
                                    class="trigger-btn"
                                    data-toggle="modal"
                                    @click="deleteRow(index, 'Bidder_Outside', data)"
                                  >
                                    <button
                                      v-show="loader_Loading_T_2[index].delete"
                                      class="btn btn-danger shadow btn-xs sharp"
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                    <div
                                      v-show="loader_Loading_C_2[index].delete"
                                      class="loadingio-spinner-rolling-yxlbb0cd94"
                                    >
                                      <div class="ldio-4c1f6xkxs72">
                                        <div></div>
                                      </div>
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> -->
                          <div class="row mt-3">
                            <div class="d-flex justify-content-start col-6">
                              <button v-if="isBiddersIndexChanged.isIndexChange" @click="setBiddersIndexPriority" class="filter-btn c-btn">
                                <div class="lds-ring" v-show="isBiddersIndexChanged.loader">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                                <span v-show="!isBiddersIndexChanged.loader">  
                                  Save Bidders changes
                                </span>
                              </button>
                            </div>
                            <div class="d-flex justify-content-end col-6">
                              <button
                                class="filter-btn c-btn"
                                @click="addRow('AddBidder')"
                              >
                                {{ lan.admin[4].Opportunity_page[0].add_partner }}
                              </button>
                              <button
                                class="filter-btn c-btn"
                                data-toggle="modal"
                                data-target="#AddAccount_Outside_Model"
                              >
                                {{ lan.admin[4].Opportunity_page[0].new_partner }}
                              </button>
                              <!-- <button
                                class="filter-btn"
                                @click="addRow('AddBidderOutside')"
                              >
                              Add outside partner
                              </button> -->
                            </div>
                          </div>
                          <span>{{
                            v$.opportunity_id.$error
                              ? " First save this opportunity then after send invitation "
                              : ""
                          }}</span>
               

                        <div class="d-flex justify-content-end mt-5 lower-btns-div">
                          <button class="filter-btn up-btn mb-3" @click="sendPreview()">
                            {{ lan.admin[4].Opportunity_page[0].Preview }}
                          </button>
                          <button
                            class="filter-btn up-btn mb-3"
                            data-target="#exampleModal-invite-customer-1"
                            data-toggle="modal"
                            style="min-width: 255px"
                          >
                            <div
                              class="lds-ring"
                              v-show="loadingSendCustomerLoader"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingSendCustomerText">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Invite_customer_to_quote
                              }}</span
                            >
                          </button>
                          <button
                            class="search-btn ml-3 up-btn mb-3"
                            @click="save('save')"
                            style="min-width: 210px"
                          >
                            <div class="lds-ring" v-show="loadingSaveOppLoader">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingSaveOppText">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Save_Opportunity
                              }}</span
                            >
                          </button>
                          <router-link to="/opportunity"
                            ><button class="filter-btn up-btn">
                              {{ lan.admin[4].Opportunity_page[0].Close }}
                            </button></router-link
                          >
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Edit email or send invitation -->
      <div
      class="modal fade"
      id="exampleModal-676"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 400px">
        <div class="modal-content">
          <div class="modal-header py-3">
            <h5 class="modal-title" id="exampleModalLabel">
              Choose
            </h5>
            <button
              id="exampleModal-676_closeX"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-md-4 p-3">
             <div class="d-flex justify-content-between">
                <button type="button" data-target="#exampleModal-989" data-toggle="modal" class="btn btn-info px-5 mb-0">
                  Edit Email
                </button> 
                <button type="button" class="btn btn-success px-5 mb-0 ml-0" @click="invetationSend()">
                  <div v-if="loader_send_invitation" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span v-if="!loader_send_invitation">Send</span>
                </button> 
             </div>
           
          </div>
          <div class="modal-footer py-2">
            <button
              type="button"
              class="btn btn-danger px-4 mb-0"
              data-dismiss="modal"
            >
              {{ lan.admin[22].Email[0].Close }}
            </button>
            <!-- <button type="button" class="filter-btn up-btn mb-0">
                {{ lan.admin[22].Email[0].Save_changes }}
            </button> -->
          </div>
        </div>
      </div>
    </div>


      <!-- Modal Edit email or send invite customer -->
      <div
      class="modal fade"
      id="exampleModal-invite-customer-1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 400px">
        <div class="modal-content">
          <div class="modal-header py-3">
            <h5 class="modal-title" id="exampleModalLabel">
              Choose
            </h5>
            <button
              id="exampleModal-invite-customer_closeX"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-md-4 p-3">
             <div class="d-flex justify-content-between">
                <button type="button" data-target="#exampleModal-invite-customer-2" data-toggle="modal" class="btn btn-info px-5 mb-0">
                  Edit Email
                </button> 
                <button type="button" class="btn btn-success px-5 mb-0 ml-0" @click="inviteToCustomer()">
                  <span>Send</span>
                </button> 
             </div>
           
          </div>
          <div class="modal-footer py-2">
            <button
              type="button"
              class="btn btn-danger px-4 mb-0"
              data-dismiss="modal"
            >
              {{ lan.admin[22].Email[0].Close }}
            </button>
            <!-- <button type="button" class="filter-btn up-btn mb-0">
                {{ lan.admin[22].Email[0].Save_changes }}
            </button> -->
          </div>
        </div>
      </div>
    </div>

      <!-- THREE DOTS Modal -->
      <div
        class="modal fade"
        :id="'myModal' + activeModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                v-if="activeModal === 'lost'"
                class="modal-title"
                id="exampleModalLabel"
              >
                {{ lan.admin[4].Opportunity_page[0].Cancel_Opportunity }}
              </h5>
              <h5
                v-else-if="activeModal === 'won'"
                class="modal-title"
                id="exampleModalLabel"
              >
                {{ lan.admin[4].Opportunity_page[0].Won_Opportunity }}
              </h5>
              <h5
                v-else-if="activeModal === 'resend-booking'"
                class="modal-title"
                id="exampleModalLabel"
              >
                {{ lan.admin[4].Opportunity_page[0].Which_partner_have_won }}
              </h5>
              <button
                id="Close"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-2">
              <!-- Modal body for lost button 1 -->
              <div v-if="activeModal === 'lost'">
                <div class="separate my-2">
                  <input
                    class="mr-2"
                    type="radio"
                    id=""
                    name="Dots"
                    value="Customer cancelled the conference without known reason"
                    v-model="lost_reason"
                  />
                  <label for="html">{{
                    lan.admin[4].Opportunity_page[0]
                      .Customer_cancelled_the_conference_without_known_reason
                  }}</label>
                </div>
                <div class="separate my-2">
                  <input
                    class="mr-2"
                    type="radio"
                    id=""
                    name="Dots"
                    value="Customer changed the dates"
                    v-model="lost_reason"
                  />
                  <label for="html">{{
                    lan.admin[4].Opportunity_page[0].Customer_changed_the_dates
                  }}</label>
                </div>
                <div class="separate my-2">
                  <input
                    class="mr-2"
                    type="radio"
                    id=""
                    name="Dots"
                    value="Customer booked an alternative venue"
                    v-model="lost_reason"
                  />
                  <label for="html">{{
                    lan.admin[4].Opportunity_page[0]
                      .Customer_booked_an_alternative_venue
                  }}</label>
                </div>
                <!-- <div class="separate my-2">
          <input
          class="mr-2"
          type="radio"
          id=""
          name="Dots"
          value="other"
          v-model="lost_reason"
         />
         <label for="html">Other:</label>
        <textarea class="init" v-model="lost_reason_textArea" cols="30" rows="10" :disabled="lost_reason == 'other' ? false : true" style="height: 100px;">
        </textarea>
        </div> -->
              </div>
              <!-- Modal body for Won Opportunity button 2 -->
              <div v-else-if="activeModal === 'won' || activeModal === 'resend-booking'">
                <span>{{
                  lan.admin[4].Opportunity_page[0].Winning_Partner
                }}</span>
                <div class="separate my-2">
                  <select
                    class="init"
                    v-model="winning_partner"
                    style="height: 43px"
                  >
                    <option
                      v-for="(bidder, index) in filter_addBidders_for_won"
                      :key="index"
                      :value="bidder.account_id"
                    >
                      {{ bidder.partner_venue_name.venue_name ? bidder.partner_venue_name.venue_name : bidder.partner_venue_name.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="filter-btn mb-0 py-2 px-3 h-auto"
                data-dismiss="modal"
              >
                {{ lan.admin[4].Opportunity_page[0].Cancel }}
              </button>
              <button
                type="button"
                class="search-btn py-2 px-3 h-auto mb-0"
                data-dismiss="modal"
                @click="save(activeModal)"
              >
                <div class="lds-ring" v-show="loading_Lost_Oppo_Loader_L">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span v-show="loading_Lost_Oppo_Loader_T">
                  {{ lan.admin[4].Opportunity_page[0].Confirm }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- THREE DOTS Modal -->

      <!-- delete model start -->
      <div id="myModal" class="modal fade">
        <div class="modal-dialog modal-confirm_1">
          <div class="modal-content">
            <div class="modal-header flex-column">
              <div class="icon-box">
                <i class="material-icons">&#xE5CD;</i>
              </div>
              <h4 class="modal-title w-100">{{ lan.admin[4].Opportunity_page[0].Are_you_sure }}?</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>
                {{ lan.admin[4].Opportunity_page[0].Do_you_really_want_to_delete_these_records }}
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                id="deletemodal"
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
              {{ lan.admin[4].Opportunity_page[0].Cancel }}
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="delete_quote_and_bidder()"
              >
              {{ lan.admin[4].Opportunity_page[0].Delete }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- delete model end -->

      <!-- invitation Revert model start -->
      <div id="invitationRevertModal" class="modal fade">
        <div class="modal-dialog modal-confirm_1">
          <div class="modal-content">
            <div class="modal-header flex-column">
              <!-- <div class="icon-box">
                <i class="material-icons">&#xE5CD;</i>
              </div> -->
              <h4 class="modal-title w-100">{{ lan.admin[4].Opportunity_page[0].Are_you_sure }}?</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>
                {{ lan.admin[4].Opportunity_page[0].Do_you_really_want_to_revert_the_change_status }}
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                id="deletemodal"
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
              {{ lan.admin[4].Opportunity_page[0].Cancel }}
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="revert_status()"
              >
              <div class="lds-ring" v-show="revert_loader">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
              <span v-show="!revert_loader"> {{ lan.admin[4].Opportunity_page[0].Confirm }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- invitation Revert model end -->

      <!-- Edit Email Or Send Invitation -->
      <EditTemplateEmail v-if="invitation_send_rawdata.data != ''"  :emailData="invitation_send_rawdata" @email-updated="invetationSend" />
      <EditTemplateEmailCustomer @email-updated="inviteToCustomer" />

      <!-- Modal Note -->
      <AddNote
        :accountDetails="All_Accounts_Details"
        :opportunitiesDetails="All_Opportunity_Details"
        :contactsDetails="All_Contacts_Details"
        :related_opportunity="related_opportunity"
        @data-updated-note="saveNewNoteHandle"
      />
      <!-- Modal Task -->
      <AddTask
        :accountDetails="All_Accounts_Details"
        :opportunitiesDetails="All_Opportunity_Details"
        :contactsDetails="All_Contacts_Details"
        :accountOwners="Accounts_Owners_Details"
        @data-updated-task="saveNewTaskHandle"
      />
      <AddAccountOutside
        @getBidders="getBidders"
        :accountOwners="Accounts_Owners_Details"
        :contactsDetails="All_Contacts_Details"
        account_type="Partner"
        check_from="SelfOppo"
      />
      <a
      id="clickModal"
      href="#statusPreliminaryBokkingModel"
      class="trigger-btn"
      data-toggle="modal"
      style="display: none"
    >
      <i class="fa fa-trash btn btn-danger shadow btn-xs sharp"></i>
    </a>
      <statusCheckModel :statusData="status_preliminary_booking"/>
      <!--**********************************
        Content body end
    ***********************************-->
    </div>
    <!--**********************************
    Main wrapper end
    ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import AddNote from "../../../components/addNote.vue";
import AddTask from "../../../components/addTask.vue";
import deleteModal from "../../../components/deleteModal";
import AddAccountOutside from "../../../components/addAccountOutside";
import EditTemplateEmail from "../../../components/editTemplateEmailForInvitation";
import EditTemplateEmailCustomer from "../../../components/editTemplateEmailForInviteCustomer";
import statusCheckModel from "../../../components/checkStatusModelForbooking";
// @ is an alias to /src
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { mapState } from 'vuex'

export default {
  name: "Edit Opportunity",
  components: { AddNote, AddTask, deleteModal,AddAccountOutside, EditTemplateEmail, statusCheckModel, EditTemplateEmailCustomer },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      opportunity_id: localStorage.getItem("editID"),
    });

    const rules = computed(() => {
      return {
        opportunity_id: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };   
  },
  data() {
    return {
      related_opportunity: "",

      // crreate dynamic table
      productOnlyAcomdation: [],
      productOnlyAcomdationMeals: [],
      productOnlyMeals: [],
      productOnlyConferenceRoom: [],
      productOnlyOthers: [],
      add_question: {
        question: "",
        type: "",
      },
      dropdown_option: "",

      // Details
      bidders: "",
      selectAccountDetails: "",
      opportunity_owner_details: "",
      selectAccountDetailsName: "",
      accountDetails: "",
      contactsDetails: "",
      opportunitiesDetails: "",
      leadsDetails: "",
      accountOwners: "",
      note_detail: [],
      task_detail: [],
      contact_details_by_account_id: [],
      customerAccountDetails: [],

      
      invitation_send_rawdata: {
        data: "",
        index: "",
        action: "",
        emailContent:"",
      },

      // quotes
      quotesDetails: "",
      quote_id: "",

      // post Oppo_D
      customer_name: "",
      customer_email: "",
      opportunity_name: "",
      lead_id: "",
      date: "",
      type_of_conference: "",
      arrival_time: "",
      departure_time: "",
      conference_room_setup: "",
      number_of_participants: '',
      // number_of_participants: this.Oppo_D != '' ? this.Oppo_D.opportunity_id : '',
      no_of_single_rooms: "",
      accomodations: "",
      no_of_participants_in_conf: "",
      number_of_participants_in_single_room: "",
      no_of_people_in_shared_double_rooms: "",
      opportunity_comments: "",
      created_at: "",
      free_text_message: "",
      date_of_conference_start: "",
      date_of_conference_end: "",
      comments: "",
      start_datetime: "",
      end_datetime: "",
      opened: "",
      description: "",
      vat: "",
      account_id: "",
      contact_id: "",
      note: [],
      task: [],
      status: "",
      seen: "",
      self: "",
      note_detail: "",
      quote_detail_count: "",
      note_detail_count: "",
      lost_reason: "",
      winning_partner: "",
      lost_reason_textArea: "",
      addMeals: [],
      addAcomdation: [
        {
          product: "",
          date: "",
          room: "",
          room_we_offer: "",
          price_for_this_quote: "",
          ordinary_price: "",
        },
      ],
      addConferenceRoom: [
        {
          product: "",
          comment: "",
          date: "",
          request_size: "",
          seating: "",
          request_size_we_can_offer: "",
          seating_we_can_offer: "",
          fee_confrence_room: "",
          ordinary_price: "",
        },
      ],
      addOtherProduct: [],
      addBidders: [],
      addBidders_Outside: [],
      addCommonQuestion: [
        {
          1: "Are the dates set or can you consider other alternative dates?",
          answer: "",
        },
        {
          2: "How many participants are you?",
          answer: "",
        },
        {
          3: "Can you give me an estimated plan for your conference?",
          answer: "",
        },
        {
          4: "Day conference or overnight stay?",
          answer: "",
        },
        {
          5: "2 full days?",
          answer: "",
        },
        {
          6: "In which region are you planning to conduct your conference?",
          answer: "",
        },
        {
          7: "Where are the participants coming from?",
          answer: "",
        },
        {
          8: "Are any participants arriving by airplane? Is it important to be close to an airport?",
          answer: "",
        },
        {
          9: " Where is your office located?",
          answer: "",
        },
        {
          10: "Where is you boss/group leader located?",
          answer: "",
        },
        {
          11: "What type of conference venue do you have in mind?",
          answer: "",
        },
        {
          12: "Is this a recurring event/conference? Where have you been before?",
          answer: "",
        },
        {
          13: "Whats the purpose of this conference? What is important for you?",
          answer: "",
        },
        {
          14: "If the venue can’t offer you single room, could you consider sharing double rooms?",
          answer: "",
        },
        {
          15: "Do you need rooms for break out sessions?",
          answer: "",
        },
        {
          16: "Are you looking for some sort of team building activity?",
          answer: "",
        },
        {
          17: "If yes, what kind? Physical demanding or mentally demanding?",
          answer: "",
        },
        {
          18: "What’s the setup of the group? Age, gender?",
          answer: "",
        },
        {
          19: "Do you have a budget for the event?",
          answer: "",
        },
        {
          20: "By when do you need the quote?",
          answer: "",
        },
      ],
      additional_questions: [],

      bidders_by_ID: "",

      //  Loading all buttons
      value: true,
      loadingSaveOppText: true,
      loadingSaveOppLoader: false,
      loader_send_invitation: false,
      loadingSaveNewTaskText: true,
      loadingSaveNewTaskLoader: false,
      loadingSaveNewNoteText: true,
      loadingSaveNewNoteLoader: false,
      loadingSendCustomerText: true,
      loadingSendCustomerLoader: false,
      loading_Lost_Oppo_Loader_T: true,
      loading_Lost_Oppo_Loader_L: false,
      hideAccordian: false,
      activeModal: null,
      loader_Loading_C: [
        {
          send: false,
          resend: false,
          booking: false,
          delete: false,
          check_booking_status: false,
        },
      ],
      loader_Loading_T: [
        {
          send: true,
          resend: true,
          booking: true,
          delete: true,
        },
      ],
      loader_Loading_C_2: [],
      loader_Loading_T_2: [],
      check_acc_Single_double: false,
      bidder_quote_delete_data: {
        data: "",
        index: "",
        check: "",
      },
      revert_data:{
        data:'',
        which_api:'',
        index:0
      },
      revert_loader:false,
      status_preliminary_booking: {
        response:'',
        additional_information:''
      },
      isEmail_Updates: false,
      isBiddersIndexChanged: {
        isIndexChange: false,
        loader: false
      },
    };
  },
  watch: {
    accomodations(newValue, oldValue) {
      // This function will be executed whenever `inputValue` changes.
      this.productOnlyAcomdation.forEach((check) => {
        // console.log('11s-',check)
        if (check.product_name == newValue) {
          // console.log('check-',check.product_name)
          if (check.type == "Double room") {
            // console.log('type-',check.type)
            this.check_acc_Single_double = true;
            return;
          } else {
            this.check_acc_Single_double = false;
            return;
          }
        }
      });
    },
    Oppo_D: {
      immediate: true, // Trigger the watcher immediately
      handler(newVal) {
      if (newVal && newVal.opportunity_id) {
        this.opportunity_owner_details = newVal.opportunity_owner_detail
        this.number_of_participants = newVal.number_of_participants;
        this.customer_email = newVal.email;
        this.arrival_time = newVal.arrival_time;
        this.departure_time = newVal.departure_time;
        this.type_of_conference = newVal.type_of_conference;
        this.accomodations = newVal.accomodations;
        this.no_of_participants_in_conf = newVal.no_of_participants_in_conf;
        this.number_of_participants_in_single_room = newVal.no_of_single_rooms;
        this.no_of_people_in_shared_double_rooms = newVal.no_of_people_in_shared_double_rooms;
        this.free_text_message = newVal.free_text_message;
        this.date_of_conference_start = newVal.date.slice(0, 10);
        this.date_of_conference_end = newVal.date.slice(14, 24);
        this.opportunity_comments = newVal.description;
        // this.opportunity_comments = newVal.opportunity_comments;
        this.opened = newVal.opened;
        this.account_id = newVal.account_id;
        this.contact_id = newVal.contact_id;
        this.vat = newVal.vat;
        this.self = newVal.self;
        this.seen = newVal.seen;
        this.status = newVal.status;
        this.selectAccountDetailsName = newVal.account_detail.name;
        this.selectAccountDetails = newVal.account_detail;
        this.note_detail = newVal.note_detail;
        this.task_detail = newVal.task_detail;
        this.lead_id = newVal.lead_id != "" ? newVal.lead_id : '';
        
        
        this.addCommonQuestion = newVal.common_questions != "" ? JSON.parse(newVal.common_questions) : '';
        this.additional_questions = newVal.additional_questions != "" ? JSON.parse(newVal.additional_questions) : '';
        this.addAcomdation = newVal.accomodation != "" ? JSON.parse(newVal.accomodation) : '';
        this.addMeals = newVal.meals != "" ? JSON.parse(newVal.meals) : '';
        this.addConferenceRoom = newVal.conference_room != "" ? JSON.parse(newVal.conference_room) : '';
        this.addOtherProduct = newVal.other_products != "" ? JSON.parse(newVal.other_products) : '';
        // this.number_of_participants = newVal.additional_questions != "" ? JSON.parse(newVal.accomodation) : '';
        // this.number_of_participants = newVal.additional_questions != "" ? JSON.parse(newVal.accomodation) : '';
        if (newVal.self == 0) {
          this.getDetails_by_lead_Id(newVal.account_id);
        }
        this.checkContact(newVal.account_id);
      }
      },
    },
    All_Products: {
      immediate: true, // Trigger the watcher immediately
      handler(newVal) {
      if (newVal) {
        this.productOnlyAcomdation.length = 0
        this.productOnlyAcomdationMeals.length = 0
        this.productOnlyMeals.length = 0
        this.productOnlyConferenceRoom.length = 0
        this.productOnlyOthers.length = 0

        newVal.forEach((element) => {
          if (
            element.product_family == "Accomodation" &&
            element.status == "1"
          ) {
            this.productOnlyAcomdation.push(element);
          }
          if (
            element.product_family == "Accomodation" ||
            (element.product_family == "Meals" && element.status == "1")
          ) {
            this.productOnlyAcomdationMeals.push(element);
          }
          if (element.product_family == "Meals" && element.status == "1") {
            this.productOnlyMeals.push(element);
          }
        });
        newVal.forEach((element) => {
          if (
            element.product_family == "Conference room" &&
            element.status == "1"
          ) {
            this.productOnlyConferenceRoom.push(element);
          }
        });
        // res.data.data.forEach((element) => {
        //   if (!element.product_family == "Conference room" || !element.product_family == "Accomodation") {
        //     console.log("Product family",element.product_family)
        //     this.productOnlyOthers.push(element);
        //   }
        // });
        newVal.forEach((element) => {
          if (element.product_family == "Others" && element.status == "1"
          ) {
            this.productOnlyOthers.push(element);
          }
        });
        this.inputValueWatcher();
      }
      },
    },
    Bidders_of_Oppo: {
      immediate: true, // Trigger the watcher immediately
      handler(newVal,oldValue) {
      if (newVal) {

        // if(oldValue){
        //   console.log('00 Bidders_of_Oppo--',this.Bidders_of_Oppo);
        //   console.log('00 newVal--',newVal);
        //   // return
        // }
        // this.addBidders.length = 0
        // this.loader_Loading_C.length = 0
        // this.loader_Loading_T.length = 0
        // if(oldValue){
        //   console.log('`12` Bidders_of_Oppo--',this.Bidders_of_Oppo);
        //   console.log('`12` newVal--',newVal);
        //   return
        // }
        this.addBidders = newVal.addBidders
        this.loader_Loading_C = newVal.loader_Loading_C
        this.loader_Loading_T = newVal.loader_Loading_T

        // newVal.forEach((element) => {
        //   // console.log("bidders_by_ID", element, index);
        //   // this.bidders_by_ID = res.data.data;
        //     this.addBidders.push({
        //       partner_venue_name: element.account_detail,
        //       status: element.status,
        //       bidder_id: element.bidder_id,
        //       present_to_customer: element.present_to_customer,
        //       send_invitation: element.send_invitation,
        //       resend_invitation: element.resend_invitation,
        //       opportunity_id: element.opportunity_id,
        //       quote_id: element.quote_id,
        //       account_id: element.account_id,
        //       preliminary: element.preliminary,
        //       is_outside_partner: element.is_outside_partner,
        //       name: element.name,
        //       email: element.email,
        //       invited: element.invited,
        //       disabled: false
        //     });
        //     this.loader_Loading_C.push({
        //       send: false,
        //       resend: false,
        //       booking: false,
        //       delete: false,
        //     });
        //     this.loader_Loading_T.push({
        //       send: true,
        //       resend: true,
        //       booking: true,
        //       delete: true,
        //     });
        // });
      }
      },
    },
    Customer_AccountDetails: {
      immediate: true, // Trigger the watcher immediately
      handler(newVal) {
      if (newVal) {
        this.customerAccountDetails = this.Customer_AccountDetails
      }
      },
    },
    All_Bidders: {
      immediate: true, // Trigger the watcher immediately
      handler(newVal) {
      if (newVal) {
        this.bidders = newVal
      }
      },
    }
  },
  created(){
    // this.$store.commit("SET_TOTAL_APIS", 8);
    this.$store.dispatch("GetOpportunityByID",this.$route.params.id).then((res) => {
      console.log('then geting', res);
    })
    this.$store.dispatch("GetAccounts")
    this.$store.dispatch("GetContacts")
    this.$store.dispatch("Get_All_Bidders")
    this.$store.dispatch("Get_Opportunites")
    this.$store.dispatch("GetProducts")
    this.$store.dispatch("Get_Accounts_owner")
    this.$store.dispatch("Get_Bidders_Opportunity_By_ID",this.$route.params.id)

  },
  computed: {
    ...mapState({
      Oppo_D: (state) => state.editOpportunity.single_Opportunity_Details,
      All_Bidders: (state) => state.bidders.all_Bidders,
      All_Opportunity_Details: (state) => state.editOpportunity.all_Opportunity_Details,
      All_Products: (state) => state.products.allProducts_details,
      All_Accounts_Details: (state) => state.accounts.all_Accounts_Details,
      All_Contacts_Details: (state) => state.contacts.all_contacts_Details,
      Bidders_of_Oppo: (state) => state.bidders.bidder_data_of_opportunity,
      Accounts_Owners_Details: (state) => state.accounts.account_owners,
      Customer_AccountDetails: (state) => state.accounts.customer_AccountDetails,
      // Progress_APIs_Calls: (state) => state.apiProgress.progress,
      // totalAPICalls: (state) => state.apiProgress.totalAPICalls,
      // completedAPICalls: (state) => state.apiProgress.completedAPICalls,
    }),

    lan() {
      return this.$store.getters.whichLanguage;
    },

    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    customer_id_details() {
      var data;
      this.contact_details_by_account_id.forEach((element) => {
        if (element.id == this.contact_id) {
          data = element;
        }
      });
      return data;
    },
    filter_bidder() {
      const data =
        this.addBidders.length != 0
          ? this.bidders.map((bidder) => ({
              ...bidder,
              disabled: this.addBidders.some(
                (element) => element.account_id === bidder.account_id
              ),
            }))
          : this.bidders;
          data.sort((a, b) => {
          // Check if 'addBidders' property exists in both objects
          if (a && b) {
            return a.venue_name == '' ? a.name.localeCompare(b.venue_name == '' ? b.name : b.venue_name) : a.venue_name.localeCompare(b.venue_name == '' ? b.name : b.venue_name);
          } else {
            // If one of the objects doesn't have 'addBidders' property, treat it as greater
            return a ? -1 : 1;
          }
        });
      return data;
    },
    filter_addBidders_for_won(){
      var data = []
      this.addBidders.forEach(element => {
        if(element.status == "Won" || element.status == "Preliminary Booked" || element.status == "Quote Submitted" || element.status == "Booked by Customer" || element.status == "Quote Lost" || element.status == "Invitation Sent")
        data.push(element)
      })
      return data
    }
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);
    // window.scroll({
    //  top: 0,
    //  left: 0,
    //  behavior: 'smooth'
    // });
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    this.state.opportunity_id = this.$route.params.id;
    this.related_opportunity = this.state.opportunity_id;
  },
  methods: {
    updateProduct(data, condition) {
      console.log('updateProduct !! :- ', data);
      const products = condition == 'Accomodation' ? this.productOnlyAcomdation : condition == 'Meals' ? this.productOnlyMeals : condition == 'OtherProduct' ? this.productOnlyOthers : this.productOnlyConferenceRoom
      const product = products.find(
        product => product.product_id === data.product_id
      );
      if (product) {
        data.product = product;
      }
    },
    invetation_Set_RawData(data, index, action){
      console.log('data, index, action--',data, index, action);
      this.invitation_send_rawdata.data = data
      this.invitation_send_rawdata.index = index
      this.invitation_send_rawdata.action = action
        // return
        var emailId = action == 'Send' ? 2 : 3 // 2 for send and 3 for resend
        axios
        .get(axios.defaults.baseURL + 'get-email-content/' + emailId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res email content--", res);
          this.invitation_send_rawdata.emailContent = res.data.data[0]
          console.log("11-invitation_send_rawdata--", this.invitation_send_rawdata);
        })
        .catch((err) => {
          this.value = false;
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });

    },
    Revert(index,data,api){
      console.log('revert index',index)
      console.log('revert data',data)
      this.revert_data.data = data
      this.revert_data.which_api = api
      this.revert_data.index = index
    },
    revert_status(){
      this.revert_loader = true
      console.log('this.revert_data.api',this.revert_data);
      // return
      var formData = new FormData();
      if(this.revert_data.which_api == 'revert-status'){
        formData.append("opportunity_id", this.state.opportunity_id);
        formData.append("bidder_id", this.revert_data.data.bidder_id);
        formData.append("quote_id", this.revert_data.data.quote_id);
      }else{
        formData.append("preliminary_booking_id", this.revert_data.data.preliminary_booking_id);
        formData.append("customer_account_id", this.revert_data.data.account_id);
        formData.append("account_id", this.revert_data.data.account_id);
        formData.append("email", this.revert_data.data.email);
        formData.append("opportunity_owner", this.opportunity_owner_details.first_name + ' ' + this.opportunity_owner_details.last_name);
        formData.append("telephone", this.opportunity_owner_details.phone);
        formData.append("phone", this.opportunity_owner_details.mobile_phone);

        formData.append("opportunity_id", this.state.opportunity_id);
        formData.append("bidder_id", this.revert_data.data.bidder_id);
        formData.append("quote_id", this.revert_data.data.quote_id);
      }
      // return
      axios
            .post(
              axios.defaults.baseURL + this.revert_data.which_api ,
              formData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              this.revert_loader = false
              // this.AddToast("Invitation Sent", " Success ", "success_1");
              this.$router.go();
              console.log(response);
            }).catch((err) => {
              console.log("err", err);
              this.revert_loader = false
              this.$store.dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);

            });
    },
    updateBidder_WithBox(){
      console.log('yy Bidders_of_Oppo--',this.Bidders_of_Oppo);
      console.log('yy addBidders--',this.addBidders);
      this.$store.dispatch("Update_Bidder_Detail_Of_Opportunity",this.addBidders)
    },
    getDetails_by_lead_Id(lead_id){
      axios
        .get(axios.defaults.baseURL + "lead/" + lead_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.leadsDetails = res.data.data[0];
          this.customer_name =
            res.data.data[0].first_name + " " + res.data.data[0].last_name;
          this.customer_email = res.data.data[0].email;
          this.arrival_time = res.data.data[0].arrival_time;
          this.type_of_conference = res.data.data[0].type_of_conference;
          this.departure_time = res.data.data[0].departure_time;
          if (res.data.data[0].do_you_accept_shared_double_room == 1) {
            this.accomodations = "Double room";
            this.check_acc_Single_double = true;
          } else {
            this.accomodations = "Single room";
            this.check_acc_Single_double = true;
          }
          this.number_of_participants =
            res.data.data[0].number_of_participants;
          this.no_of_participants_in_conf =
            res.data.data[0].number_of_participants_in_conference_room;
          this.number_of_participants_in_single_room =
            res.data.data[0].number_of_participants_in_single_room;
          this.no_of_people_in_shared_double_rooms =
            res.data.data[0].number_of_participants_in_shared_double_room;
          this.date_of_conference_start =
            res.data.data[0].date_of_conference.slice(0, 10);
          this.date_of_conference_end =
            res.data.data[0].date_of_conference.slice(14, 24);
          // this.created_at = res.data.data[0].created_at;
          this.free_text_message = res.data.data[0].free_text_message;
          this.date_of_conference_start =
            res.data.data[0].date_of_conference;
          this.date_of_conference_end = res.data.data[0].date_of_conference;
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },
    openModal(modalText) {
      // if(modalText == 'resend-booking'){
      //   this.save(modalText)
      //   return
      // }
      if (modalText == 'resend-booking' ? false : (this.status == "Won" || this.status == "Lost")) {
        this.AddToast(
          "This opportunity already " + this.status,
          "Won Validation",
          "info_1"
        );
        return;
      }
      this.activeModal = modalText;
      this.$nextTick(() => {
        $(`#myModal${modalText}`).modal("show");
      });
    },
    inputValueWatcher() {
      this.productOnlyAcomdation.forEach((check) => {
        // console.log('11s-',check)
        if (check.product_name == this.accomodations) {
          // console.log('check-',check.product_name)
          if (check.type == "Double room") {
            // console.log('type-',check.type)
            this.check_acc_Single_double = true;
            return;
          } else {
            this.check_acc_Single_double = false;
            return;
          }
        }
      });
    },
    getBidders(data){
      // *************GET bidders**********
    axios
      .get(axios.defaults.baseURL + "bidders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.bidders = res.data.data;
        // this.bidders_clone = res.data.data;
      
        if(data != undefined){
          this.addBidders.push({
            partner_venue_name: "",
            status: "Invitation not sent",
            present_to_customer: false,
            send_invitation: 1,
            resend_invitation: 0,
            opportunity_id: "",
            quote_id: "",
            account_id: data != undefined ? data.account_id : '',
            preliminary: 0,
            invited: 0,
            searchQuery: "",
            is_outside_partner: "",
            name: "",
          });
        }
        
        this.loader_Loading_C.push({
          send: false,
          resend: false,
        });
        this.loader_Loading_T.push({
          send: true,
          resend: true,
        });

      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        this.value = false;
      });
    },
    check_preliminary_status(index, data){
      console.log('check_preliminary_status--',index, data);
      console.log('preliminary_booking_id--', data.preliminary_booking_id);
      // return
      this.loader_Loading_C[index].check_booking_status = true
      axios
      .get(axios.defaults.baseURL + "get-preliminary-booking/" + data.preliminary_booking_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res_booking) => {
        console.log("res bidder-", res_booking);
        if(res_booking.data.data[0].response == null){
          data.tooltip_title = 'Partner has not responded yet'
          this.status_preliminary_booking.response = 'Partner has not responded yet'
          var modal = document.getElementById("clickModal");
          modal.click();
        }else{
          data.tooltip_title = res_booking.data.data[0].response + ', ' + res_booking.data.data[0].additional_information
          this.status_preliminary_booking.response = res_booking.data.data[0].response
          this.status_preliminary_booking.additional_information = res_booking.data.data[0].additional_information
          var modal = document.getElementById("clickModal");
          modal.click();
        }
        this.loader_Loading_C[index].check_booking_status = false
      }).catch((err) =>{
        console.log(err)
        this.loader_Loading_C[index].check_booking_status = false
      })
    },
    preliminaryBooking(data, index, text) {
      console.log(data);
      console.log('Oppo_D',this.Oppo_D.date);
      // return
      if (this.status == "Won" || this.status == "Lost") {
        this.AddToast(
          "This opportunity already " + this.status,
          "Won Validation",
          "info_1"
        );
        return;
      }
      this.loader_Loading_C[index].booking = true;
      this.loader_Loading_T[index].booking = false;

     if(data.partner_venue_name.email){
        console.log("booking", data, index, text);
      var formData = new FormData();
      formData.append("date", this.Oppo_D.date);
      formData.append("opportunity_owner", this.opportunity_owner_details.first_name + ' ' + this.opportunity_owner_details.last_name);
      formData.append("opportunity_owner_email", this.opportunity_owner_details.email);
      formData.append("phone", this.opportunity_owner_details.mobile_phone);
      formData.append("telephone", this.opportunity_owner_details.phone);
      formData.append("email", data.partner_venue_name.email);
      formData.append("quote_id", data.quote_id);
      formData.append("opportunity_id", data.opportunity_id);
      formData.append("account_id", data.account_id);
      formData.append("bidder_id", data.bidder_id);
      formData.append("customer_account_id", this.account_id);
      formData.append("contact_id", "");
      formData.append("preliminary", 1);
      formData.append("status", "Preliminary Booking");
      axios
        .post(axios.defaults.baseURL + "book-preliminary", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res booking", res);
          this.addBidders[index].preliminary = 1;
          //  // *************edit bidder***********
          var formData = new FormData();
          formData.append(
            "partner_venue_name",
            JSON.stringify(data.partner_venue_name)
          );
          formData.append("status", data.status);
          formData.append("index_priority", data.index_priority);
          formData.append("present_to_customer", data.present_to_customer);
          formData.append("send_invitation", data.send_invitation);
          formData.append("resend_invitation", data.resend_invitation);
          formData.append("quote_id", res.data.data.quote_id);
          formData.append("bidder_id", data.bidder_id);
          formData.append("account_id", data.partner_venue_name.account_id);
          formData.append("opportunity_id", data.opportunity_id);
          formData.append("invited", data.invited);
          formData.append("preliminary", 1);

          axios
            .post(
              axios.defaults.baseURL + "edit-bidder/" + data.bidder_id,
              formData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              console.log("response", response);
              this.loader_Loading_C[index].resend = false;
              this.loader_Loading_T[index].resend = true;
              this.addBidders[index].preliminary = 1;
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
            })
            .catch((err) => {
              console.log("err", err);
              this.loader_Loading_C[index].resend = false;
              this.loader_Loading_T[index].resend = true;
              this.$store.dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });

          // *************************

          this.loader_Loading_C[index].booking = false;
          this.loader_Loading_T[index].booking = true;
        })
        .catch((err) => {
          this.loader_Loading_C[index].booking = false;
          this.loader_Loading_T[index].booking = true;
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          console.log("err", err);
        });
      }else{
        this.AddToast(
          this.lan.Please_add_Email_id_for_this_venue_before_send_invitaion,
          "Information",
          "info_1"
        );
      }
    },
    hideOpenAccordian() {
      if (this.hideAccordian == false) {
        this.hideAccordian = true;
      } else {
        this.hideAccordian = false;
      }
    },
    editNote(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Note", params: { id: id } });
    },
    editTask(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Task", params: { id: id } });
    },
    checkContact(related_account) {
      axios
        .get(axios.defaults.baseURL + "get-contact/" + related_account, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.contact_details_by_account_id = res.data.data;
          // alert(this.lead_details);
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    // invetationSend_outside_partner(data, index, text){
    //   console.log('data',data);
    //   console.log('text',text);
    //   this.v$.opportunity_id.$touch();
    //   if(text == 0){
    //     return
    //   }
    //   if (!this.v$.opportunity_id.$invalid) {
    //     if(data.name && data.email){
    //       this.loader_Loading_C_2[index].send = true;
    //       this.loader_Loading_T_2[index].send = false;
    //       var formData = new FormData();
    //       formData.append("name", data.name);
    //       formData.append("email", data.email);
    //       formData.append("customer_account_id", this.account_id);
    //       axios
    //         .post(axios.defaults.baseURL + "invite-new-partner/" + this.state.opportunity_id , formData, {
    //           headers: {
    //             Authorization: "Bearer " + localStorage.getItem("token"),
    //           },
    //         })
    //         .then((res) => {
    //           console.log("res", res);
    //           this.loader_Loading_C_2[index].send = false;
    //           this.loader_Loading_T_2[index].send = true;
    //           this.$store.dispatch("checkErrorAndSendToast", [
    //           res,
    //             "Success",
    //           ]);
    //         }).catch((err) => {
    //           this.loader_Loading_C_2[index].send = false;
    //           this.loader_Loading_T_2[index].send = true;
    //           console.log("err", err);
    //           this.$store.dispatch("checkErrorAndSendToast", [
    //             err.response,
    //             "error",
    //           ]);
    //         })
    //     }else{
    //       this.AddToast(
    //       "Please fill all the fields",
    //       "Validation",
    //       "info_1"
    //     );
    //     return;
    //     }
    //   }else {
    //     this.AddToast(
    //       "First save this opportunity then after send invitation",
    //       "First Save opportunity",
    //       "info_1"
    //     );
    //     console.log("return hai");
    //     return;
    //   }
    // },
    invetationSend(isEmail) {
      console.log('isEmail--',isEmail);
      // return
      var data = this.invitation_send_rawdata.data
      var index = this.invitation_send_rawdata.index
      var text = this.invitation_send_rawdata.action
      console.log('this.invitation_send_rawdata',this.invitation_send_rawdata);
      console.log("data", data);
      console.log("index", index);
      console.log("text", text);
      // return
      if (this.status == "Won" || this.status == "Lost") {
        this.AddToast(
          this.lan.This_opportunity_already + this.status,
          this.lan.Won + ' ' + this.lan.Validation,
          "info_1"
        );
        return;
      }
      console.log("invetationSend", data, index, text);
      this.v$.opportunity_id.$touch();
      if (!this.v$.opportunity_id.$invalid) {
        var API
        this.bidders.every((element) => {
          if (element.account_id == data.account_id) {
            data.partner_venue_name = element;
            if(element.is_outside_partner == 1 || element.is_outside_partner == '1'){
                API = 'invite-outside-partner'
            }else{
              API ='send-invitation';
            }
            return false;
          } else {
            return true;
          }
        });
        if(!data.partner_venue_name.email){
          this.AddToast(this.lan.Please_add_Email_id_for_this_venue_before_send_invitaion, "Information", "info_1");
          return
        }
        if (text == "Send") {
          // this.loader_send_invitation = true;
          document.getElementById('exampleModal-676_closeX').click()
          this.loader_Loading_C[index].send = true;
          this.loader_Loading_T[index].send = false;
          // return

          var formData = new FormData();

          formData.append("opportunity_id", this.state.opportunity_id);
          formData.append("account_id", data.account_id);
          formData.append("customer_account_id", this.account_id);
          formData.append("email", data.partner_venue_name.email);
          formData.append(
            "contact_id",
            data.partner_venue_name.primary_contact
          );
          formData.append("status", "New");
          if(isEmail != undefined){
            formData.append("is_template_changed",isEmail[0] == 1  ? 1 : 0);
            formData.append("email_body_content", isEmail[1]);
            formData.append("bccEmail", isEmail[2]);
            formData.append("subject", isEmail[3]);
          }else{
            formData.append("is_template_changed", 0);
          }
          // return
          axios
            .post(axios.defaults.baseURL + API, formData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              console.log("response", response);

              // ************************
              var formData = new FormData();
              formData.append(
                "partner_venue_name",
                JSON.stringify(data.partner_venue_name)
              );
              formData.append("index_priority", data.index_priority);
              formData.append("status", "Invitation Sent");
              formData.append("present_to_customer", data.present_to_customer);
              formData.append("send_invitation", 0);
              formData.append("resend_invitation", 1);
              formData.append("quote_id", response.data.data.quote_id);
              formData.append("account_id", data.partner_venue_name.account_id);
              formData.append("opportunity_id", this.state.opportunity_id);
              formData.append("preliminary", data.preliminary);
              formData.append("invited", data.invited);
              console.log("form data for bidder-", formData);
              axios
                .post(axios.defaults.baseURL + "bidders", formData, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  this.$store.dispatch("checkErrorAndSendToast", [response,'Success']);
                  // this.loader_send_invitation = false;
                  this.loader_Loading_C[index].send = false;
                  this.loader_Loading_T[index].send = true;
                  this.addBidders[index].status = "Invitation Sent";
                  this.addBidders[index].send_invitation = 0;
                  this.addBidders[index].resend_invitation = 1;
                  console.log("res", res);
                })
                .catch((err) => {
                  console.log("err", err);
                  // this.loader_send_invitation = false;
                  this.loader_Loading_C[index].send = false;
                  this.loader_Loading_T[index].send = true;
                  this.$store.dispatch("checkErrorAndSendToast", [
                    err.response,
                    "error",
                  ]);
                });
              // **************************
            })
            .catch((err) => {
              console.log("err", err);
              // this.loader_send_invitation = false;
              this.loader_Loading_C[index].send = false;
              this.loader_Loading_T[index].send = true;
              this.$store.dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        } else {
          document.getElementById('exampleModal-676_closeX').click()
          if(this.addBidders[index].disabled == true){
            return ;
          }
          this.loader_Loading_C[index].resend = true;
          this.loader_Loading_T[index].resend = false;

          var formData = new FormData();
          formData.append("opportunity_id", this.state.opportunity_id);
          formData.append("account_id", data.account_id);
          formData.append("bidder_id", data.bidder_id);
          formData.append("customer_account_id", this.account_id);
          formData.append("email", data.partner_venue_name.email);
          formData.append(
            "contact_id",
            data.partner_venue_name.primary_contact
          );
          formData.append("status", "New");
          if(isEmail != undefined){
            formData.append("is_template_changed",isEmail[0] == 1  ? 1 : 0);
            formData.append("email_body_content", isEmail[1]);
            formData.append("bccEmail", isEmail[2]);
            formData.append("subject", isEmail[3]);
          }else{
            formData.append("is_template_changed", 0);
          }
          axios
            .post(
              axios.defaults.baseURL + "resend-invitation/" + data.quote_id,
              formData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              console.log("resend-invitation --response-", response);

              // *************edit bidder***********
              var formData = new FormData();
              formData.append(
                "partner_venue_name",
                JSON.stringify(data.partner_venue_name)
              );
              formData.append("index_priority", data.index_priority);
              formData.append("status", "Invitation Sent");
              formData.append("present_to_customer", false);
              formData.append("send_invitation", 0);
              formData.append("resend_invitation", 1);
              formData.append("quote_id", response.data.data.quote_id);
              formData.append("account_id", data.partner_venue_name.account_id);
              formData.append("opportunity_id", this.state.opportunity_id);
              formData.append("bidder_id", data.bidder_id);
              formData.append("preliminary_booking_id", data.preliminary_booking_id);
              formData.append("invited", 0);
              formData.append("preliminary",data.preliminary);
              console.log("form data for bidder resend-", formData);

              axios
                .post(
                  axios.defaults.baseURL + "edit-bidder/" + data.bidder_id,
                  formData,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then((res) => {
                  console.log("res", res);
                  this.loader_Loading_C[index].resend = false;
                  this.loader_Loading_T[index].resend = true;
                  this.addBidders[index].status = "Invitation Sent";
                  this.addBidders[index].send_invitation = 0;
                  this.addBidders[index].resend_invitation = 1;
                  this.addBidders[index].present_to_customer = false;
                  this.addBidders[index].invited = 0;
                  this.addBidders[index].disabled = true;
                  this.$store.dispatch("checkErrorAndSendToast", [
                    response,
                    "Success",
                  ]);
                })
                .catch((err) => {
                  console.log("err", err);
                  this.loader_Loading_C[index].resend = false;
                  this.loader_Loading_T[index].resend = true;
                  this.$store.dispatch("checkErrorAndSendToast", [
                    err.response,
                    "error",
                  ]);
                });

              // *************************
            })
            .catch((err) => {
              console.log("err", err);
              this.loader_Loading_C[index].resend = false;
              this.loader_Loading_T[index].resend = true;
              this.$store.dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });

          console.log("reach");
        }
      } else {
        this.AddToast(
          this.lan.First_save_this_opportunity_then_after_send_invitation,
          this.lan.First_Save_opportunity,
          "info_1"
        );
        return;
      }
    },
    save(reason) {
      if (reason == 'resend-booking' ? false : (this.status == "Won" || this.status == "Lost")) {
        this.AddToast(
          this.lan.This_opportunity_already + this.status,
          this.lan.Won + ' ' + this.lan.Validation,
          "info_1"
        );
        return;
      }
      if (reason == "save") {
        this.loadingSaveOppLoader = true;
        this.loadingSaveOppText = false;
      } else if (reason == "lost") {
        this.loading_Lost_Oppo_Loader_L = true;
        this.loading_Lost_Oppo_Loader_T = false;
        var formData = new FormData();
        if (this.lost_reason == "other") {
          formData.append("lost_reason", this.lost_reason_textArea);
        } else {
          formData.append("lost_reason", this.lost_reason);
        }
        axios
          .post(
            axios.defaults.baseURL +
              "lost-opportunity/" +
              this.state.opportunity_id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.loading_Lost_Oppo_Loader_L = false;
            this.loading_Lost_Oppo_Loader_T = true;
            this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
            return;
          })
          .catch((err) => {
            this.loading_Lost_Oppo_Loader_L = false;
            this.loading_Lost_Oppo_Loader_T = true;
            this.$store.dispatch("checkErrorAndSendToast", [err.response,"error",]);
            return;
          });
        return;
      } else {
        const confirmMessage = "Are you sure your selected partner are won?";
        if (confirm(confirmMessage)) {
          this.loading_Lost_Oppo_Loader_L = true;
          this.loading_Lost_Oppo_Loader_T = false;
          // return
          var api = reason == 'resend-booking' ? 'resend-booking-request/' : 'won-opportunity/'
          var formData = new FormData();
          formData.append("winning_partner", this.winning_partner);
          axios
            .post(
              axios.defaults.baseURL +
              api +
                this.state.opportunity_id,
              formData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              this.loading_Lost_Oppo_Loader_L = false;
              this.loading_Lost_Oppo_Loader_T = true;
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
              document.getElementById("Close").click();
              return;
            })
            .catch((err) => {
              this.loading_Lost_Oppo_Loader_L = false;
              this.loading_Lost_Oppo_Loader_T = true;
              this.$store.dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
          return;
        } else {
          return;
        }
      }

      if (this.self == 1) {
        this.contact_details_by_account_id.forEach((element) => {
          if (element.id == this.contact_id) {
            this.customer_email = element.email;
          }
        });
      }

      var formData = new FormData();

      // formData.append("start_datetime", "2023-03-11 17:00:00");
      formData.append(
        "date",
        this.date_of_conference_start + " to " + this.date_of_conference_end
      );
      formData.append("arrival_time", this.arrival_time);
      // formData.append("end_datetime", "2023-03-11 17:00:00");
      formData.append("departure_time", this.departure_time);
      formData.append("type_of_conference", this.type_of_conference);
      formData.append("opportunity_owner", this.opportunity_owner_details.id);
      formData.append("number_of_participants", this.number_of_participants);
      formData.append("opportunity_comments", this.opportunity_comments);
      formData.append("comments", this.free_text_message);
      formData.append("free_text_message", this.free_text_message);
      formData.append("vat", this.vat);
      formData.append("account_id", this.account_id);
      formData.append("contact_id", this.contact_id);
      formData.append("lead_id", this.lead_id);
      formData.append("shared_double_room", "");
      formData.append("accomodations", this.accomodations);
      formData.append("email", this.customer_email);
      formData.append("description", this.description);
      formData.append("opened", this.opened);

      formData.append(
        "no_of_single_rooms",
        this.number_of_participants_in_single_room
      );
      formData.append(
        "no_of_people_in_shared_double_rooms",
        this.no_of_people_in_shared_double_rooms
      );
      formData.append("opportunity_name", "");
      formData.append(
        "no_of_participants_in_conf",
        this.no_of_participants_in_conf
      );
      formData.append("self", this.self);
      formData.append("seen", 0);
      formData.append(
        "common_questions",
        JSON.stringify(this.addCommonQuestion)
      );
      // formData.append("note", this.note);
      // formData.append("task", this.task);
      if (this.note != "") {
        formData.append("note", JSON.stringify(this.note));
      } else {
        formData.append("note", "");
      }
      if (this.task != "") {
        formData.append("task", JSON.stringify(this.task));
      } else {
        formData.append("task", "");
      }
      formData.append(
        "additional_questions",
        JSON.stringify(this.additional_questions)
      );

      var valid_conference = []
        var valid_accomodation = []
        var valid_meals = []
        var valid_otherproduct = []
        this.addAcomdation.forEach(element => {
          console.log('addAcomdation--1---',element);
          if(element.product != ''){
            valid_accomodation.push(element)
          }
        });
        this.addMeals.forEach(element => {
          console.log('addMeals--1---',element);
          if(element.product != ''){
            valid_meals.push(element)
          }
        });
        this.addOtherProduct.forEach(element => {
          console.log('addOtherProduct--1---',element);
          if(element.product != ''){
            valid_otherproduct.push(element)
          }
        });
        this.addConferenceRoom.forEach(element => {
          console.log('addConferenceRoom--1---',element);
          if(element.product != ''){
            valid_conference.push(element)
          }
        });

        formData.append("accomodation", JSON.stringify(valid_accomodation));
        console.log('valid_meals-', valid_meals);
        
        formData.append("meals", JSON.stringify(valid_meals));
        formData.append(
          "conference_room",
          JSON.stringify(valid_conference)
        );
        formData.append("other_products", JSON.stringify(valid_otherproduct));
      formData.append("bidders", JSON.stringify(this.addBidders));

      if (reason == "save") {
        formData.append("lost_reason", "");
        formData.append("status", "Open");
      } else {
        if (this.lost_reason == "other") {
          formData.append("lost_reason", this.lost_reason_textArea);
        } else {
          formData.append("lost_reason", this.lost_reason);
        }
        formData.append("status", "Lost");
      }

      axios
        .post(
          axios.defaults.baseURL +
            "edit-opportunity/" +
            this.state.opportunity_id,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log("res", res);
          if (this.self == 0) {
            this.editLead(res);
          } else {
            (this.loadingSaveOppLoader = false),
              (this.loadingSaveOppText = true);
            // this.$router.push({ path: "/opportunity" });
            this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          }
        })
        .catch((err) => {
          (this.loadingSaveOppLoader = false),
            (this.loadingSaveOppText = true),
            console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },
    editLead(message) {
      if (this.status == "Won" || this.status == "Lost") {
        this.AddToast(
          this.lan.This_opportunity_already + this.status,
          this.lan.Won + ' ' + this.lan.Validation,
          "info_1"
        );
        return;
      }
      let formData = new FormData();
      formData.append("first_name", this.leadsDetails.first_name);
      formData.append("last_name", this.leadsDetails.last_name);
      formData.append("email", this.leadsDetails.email);
      formData.append("company", this.leadsDetails.company);
      formData.append("number_of_participants", this.number_of_participants);
      formData.append(
        "number_of_participants_in_shared_double_room",
        this.no_of_people_in_shared_double_rooms
      );
      formData.append(
        "do_you_accept_shared_double_room",
        this.leadsDetails.do_you_accept_shared_double_room
      );
      formData.append(
        "number_of_participants_in_single_room",
        this.number_of_participants_in_single_room
      );

      if (this.date_of_conference_start && this.date_of_conference_end) {
        formData.append(
          "date_of_conference",
          this.date_of_conference_start +
            " " +
            "to" +
            " " +
            this.date_of_conference_end
        );
      } else if (this.startdate_of_conference) {
        formData.append("date_of_conference", this.date_of_conference_start);
      }
      formData.append(
        "number_of_participants_in_conference_room",
        this.no_of_participants_in_conf
      );
      formData.append(
        "conference_room_setup",
        this.leadsDetails.conference_room_setup
      );
      formData.append("seating_type", this.leadsDetails.seating_type);
      formData.append(
        "notice_regarding_shared_double_room",
        this.leadsDetails.notice_regarding_shared_double_room
      );
      formData.append("region", this.leadsDetails.region);
      formData.append("city", this.leadsDetails.city);
      formData.append("free_text_message", this.leadsDetails.free_text_message);
      formData.append("contact_me_by", this.leadsDetails.contact_me_by);
      formData.append("arrival_time", this.arrival_time);
      formData.append("departure_time", this.departure_time);
      formData.append("type_of_conference", this.type_of_conference);
      formData.append("telephone", this.leadsDetails.telephone);
      formData.append("price_wanted", this.leadsDetails.price_wanted);
      formData.append("status", this.leadsDetails.status);
      axios
        .post(
          axios.defaults.baseURL + "edit-lead/" + this.$route.params.id,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // this.lead_details = res.data.data[0];
          (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [message,'Success']);
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },
    saveNewNoteHandle(data) {
      this.note.push(data.note_id);
      this.note_detail.push({
        note_id: data.note_id,
        subject: data.subject,
        comment: data.comment,
      });
    },
    saveNewTaskHandle(data) {
      this.task.push(data.task_id);
      this.task_detail.push({
        task_id: data.task_id,
        subject: data.subject,
        comment: data.comment,
      });
    },
    inviteToCustomer(isEmail) {
      if (this.status == "Won" || this.status == "Lost") {
        this.AddToast(
          this.lan.This_opportunity_already + this.status,
          this.lan.Won + ' ' + this.lan.Validation,
          "info_1"
        );
        return;
      }
      this.loadingSendCustomerText = false;
      this.loadingSendCustomerLoader = true;
      var check = false;

      this.addBidders.forEach((element,index) => {
        console.log('element--',element);
        console.log('index--',index);
        if (
          element.present_to_customer == true ||
          element.present_to_customer == "true"
        ) {
          check = true;
        }
      });
      // return
      // $('#exampleModal-invite-customer-1').modal('show');
      if (check == true) {
        document.getElementById('exampleModal-invite-customer_closeX').click()
        // return
        this.addBidders.forEach((element, index) => {
          var formData = new FormData();
          formData.append(
            "partner_venue_name",
            JSON.stringify(element.partner_venue_name)
          );
          formData.append("index_priority", element.index_priority);
          formData.append("account_id", element.partner_venue_name.account_id);
          formData.append("quote_id", element.quote_id);
          formData.append("bidder_id", element.bidder_id);
          formData.append("preliminary_booking_id", element.preliminary_booking_id);
          formData.append("status", element.status);
          formData.append("present_to_customer", element.present_to_customer);
          formData.append("send_invitation", element.send_invitation);
          formData.append("resend_invitation", element.resend_invitation);
          formData.append("opportunity_id", element.opportunity_id);
          formData.append("preliminary", element.preliminary);
          if (
            (element.present_to_customer == "true" ||
            element.present_to_customer == true) &&
              (element.invited == 0 || element.invited == "0")
          ) {
            formData.append("invited", 1);
          } else {
            formData.append("invited", element.invited);
          }

          axios
            .post(
              axios.defaults.baseURL + "edit-bidder/" + element.bidder_id,
              formData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              // this.AddToast(res.data.message, " Success ", "success_1");
              if (
                element.present_to_customer == "true" ||
                (element.present_to_customer == true &&
                  (element.invited == 0 || element.invited == "0"))
              ) {
                this.addBidders[index].invited = 1;
              }
            })
            .catch((err) => {
              console.log("err", err);
              // this.$store.dispatch("checkErrorAndSendToast", [err.response,'error']);
            });
        });
      } else {
        this.AddToast(
          this.lan.Please_select_Present_to_customer,
          " Information ",
          "info_1"
        );
        this.loadingSendCustomerText = true;
        this.loadingSendCustomerLoader = false;
        return;
      }
      setTimeout(() => {
        var formData = new FormData();
        // formData.append("email", 'aryannsarkar74@gmail.com');
        formData.append("email", this.customer_id_details.email);
        // formData.append(
        //   "customer_name",
        //   this.customer_id_details.first_name +
        //     " " +
        //     this.customer_id_details.last_name
        // );
        formData.append("customer_first_name", this.customer_id_details.first_name);
        formData.append("customer_last_name", this.customer_id_details.last_name);
        formData.append("opportunity_owner", this.opportunity_owner_details.first_name + " " + this.opportunity_owner_details.last_name);
        formData.append("opportunity_owner_email", this.opportunity_owner_details.email);
        if(isEmail != undefined){
          formData.append("is_template_changed",isEmail[0] == 1  ? 1 : 0);
          formData.append("email_body_content", isEmail[1]);
        }else{
          formData.append("is_template_changed", 0);
        }

        axios
          .post(
            axios.defaults.baseURL +
              "invite-customer/" +
              this.state.opportunity_id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            // this.opportunitiesDetails = res.data.data;
            this.loadingSendCustomerText = true;
            this.loadingSendCustomerLoader = false;
            this.AddToast(this.lan.Invitation_Sent, " Success ", "success_1");
          })
          .catch((err) => {
            this.loadingSendCustomerText = true;
            this.loadingSendCustomerLoader = false;
            console.log("err", err);
            this.$store.dispatch("checkErrorAndSendToast", [
              err.response,
              "error",
            ]);
            // if (err.response.statusText == "Unauthorized") {
            //   this.$router.push("/login").then(() => {
            //     this.$router.go();
            //   });
            // }
          });
      }, 5000);
    },
    addRow(check) {
      if (check == "Accomodation") {
        this.addAcomdation.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          date: this.date_of_conference_start,
          room: this.number_of_participants,
          room_we_offer: "",
          price_for_this_quote: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
        console.log(this.addAcomdation);
      } else if (check == "Meals") {
        this.addMeals.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          date: this.date_of_conference_start,
          room: this.number_of_participants,
          room_we_offer: "",
          price_for_this_quote: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
      } else if (check == "Product") {
        this.addConferenceRoom.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          comment: "",
          date: this.date_of_conference_start,
          request_size: this.no_of_participants_in_conf,
          seating: "",
          request_size_we_can_offer: "",
          seating_we_can_offer: "",
          fee_confrence_room: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
      } else if (check == "OtherProduct") {
        this.addOtherProduct.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          date: this.date_of_conference_start,
          comment: "",
          room: this.number_of_participants,
          room_we_offer: "",
          price_for_this_quote: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
      } else if (check == "AddBidder") {
        this.addBidders.push({
          row_id: Date.now().toString(),
          index_priority : this.addBidders.length + 1 ,
          partner_venue_name: "",
          status: "Invitation not sent",
          present_to_customer: false,
          send_invitation: 1,
          resend_invitation: 0,
          opportunity_id: "",
          quote_id: "",
          account_id: "",
          preliminary: 0,
          invited: 0,
          is_outside_partner: "",
          name: "",
          // partnerDetails: "",
          // status: "Invitation not sent",
          // sendInvitation: 1,
          // resendInvitation: 0,
          // quote: "",
          // presentCustomer: 0,
        });

        this.loader_Loading_C.push({
          send: false,
          resend: false,
        });
        this.loader_Loading_T.push({
          send: true,
          resend: true,
        });
      }
      // else if(check == 'AddBidderOutside'){
      //   console.log('outside')
      //   this.addBidders_Outside.push({
      //     partner_venue_name: "",
      //     status: "Invitation not sent",
      //     present_to_customer: false,
      //     send_invitation: 1,
      //     resend_invitation: 0,
      //     opportunity_id: "",
      //     quote_id: "",
      //     account_id: "",
      //     preliminary: 0,
      //     invited: "",
      //     searchQuery: "",
      //     is_outside_partner: "",
      //     name: "",
      //     email: "",
      //   });
      //   this.loader_Loading_C_2.push({
      //     send: false,
      //     resend: false,
      //   });
      //   this.loader_Loading_T_2.push({
      //     send: true,
      //     resend: true,
      //   });
      // }
    },
    addDropdown(indexx) {
      this.additional_questions.forEach((drop, index) => {
        if (index == indexx) {
          if (this.dropdown_option != "") {
            drop.dropdown.push({ name: this.dropdown_option });
            this.dropdown_option = "";
          }
        }
      });
    },
    deleteRow(index, check, data) {
      if (check == "Accomodation") {
        this.addAcomdation.splice(index, 1);
      } else if (check == "Product") {
        this.addConferenceRoom.splice(index, 1);
      } else if (check == "OtherProduct") {
        this.addOtherProduct.splice(index, 1);
      } else if (check == "Bidder") {
        if (data.status == "Invitation not sent") {
          this.addBidders.splice(index, 1);
        } else {
          this.bidder_quote_delete_data.data = data;
          this.bidder_quote_delete_data.index = index;
          this.bidder_quote_delete_data.check = check;
          // delete_quote_and_bidder(index,data)
        }
      } else if (check == "Bidder_Outside") {
        if (data.status == "Invitation not sent") {
          this.addBidders_Outside.splice(index, 1);
        } else {
          this.bidder_quote_delete_data.data = data;
          this.bidder_quote_delete_data.index = index;
          this.bidder_quote_delete_data.check = check;
          // delete_quote_and_bidder(index,data)
        }
      }
      else if (check == "Meals") {
        this.addMeals.splice(index, 1);
      }
    },
    delete_quote_and_bidder() {
      if (this.status == "Won" || this.status == "Lost") {
        this.AddToast(
          this.lan.This_opportunity_already + this.status,
          this.lan.Won + ' ' + this.lan.Validation,
          "info_1"
        );
        return;
      }
      if(this.bidder_quote_delete_data.check == 'Bidder'){
        console.log('dff--',this.loader_Loading_C[this.bidder_quote_delete_data.index]);
        this.loader_Loading_C[this.bidder_quote_delete_data.index].delete = true;
        this.loader_Loading_T[this.bidder_quote_delete_data.index].delete = false;
      }else{
        console.log('dff-5--',this.loader_Loading_C_2[this.bidder_quote_delete_data.index]);
        this.loader_Loading_C_2[this.bidder_quote_delete_data.index].delete = true;
        this.loader_Loading_T_2[this.bidder_quote_delete_data.index].delete = false;
      }
      // console.log(
      //   "delete_quote --- ",
      //   this.bidder_quote_delete_data.data.quote_id
      // );
      document.getElementById("deletemodal").click();
      // return
      axios
        .post(
          axios.defaults.baseURL +
            "delete-quote/" +
            this.bidder_quote_delete_data.data.quote_id,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log("res", res);
          axios
            .post(
              axios.defaults.baseURL +
                "delete-bidder/" +
                this.bidder_quote_delete_data.data.bidder_id,
              {},
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              console.log("res", res);
              this.loader_Loading_C[
                this.bidder_quote_delete_data.index
              ].delete = false;
              this.loader_Loading_T[
                this.bidder_quote_delete_data.index
              ].delete = true;
              this.AddToast(
                this.lan.Quote_and_bidder_deleted_successfully,
                " Success ",
                "success_1"
              );
              if(this.bidder_quote_delete_data.check == 'Bidder'){
                this.addBidders.splice(this.bidder_quote_delete_data.index, 1);
              }else{
                this.addBidders_Outside.splice(this.bidder_quote_delete_data.index, 1);
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.loader_Loading_C[
                this.bidder_quote_delete_data.index
              ].delete = false;
              this.loader_Loading_T[
                this.bidder_quote_delete_data.index
              ].delete = true;
              this.$store.dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
        .catch((err) => {
          console.log("err", err);
          this.loader_Loading_C[
            this.bidder_quote_delete_data.index
          ].delete = false;
          this.loader_Loading_T[
            this.bidder_quote_delete_data.index
          ].delete = true;
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },
    addQuestion() {
      if (this.add_question.type == "dropdown") {
        this.additional_questions.push({
          row_id: Date.now().toString(),
          inputType: this.add_question.type,
          question: this.add_question.question,
          answer: "",
          dropdown: [],
          answertags: [],
          answerMultiple: [],
          maxSelect: 1,
          isShowOrNot: true,
          extra2: "",
        });
        this.add_question.question = "";
      } else if (
        this.add_question.type == "text" ||
        this.add_question.type == "radio"
      ) {
        this.additional_questions.push({
          row_id: Date.now().toString(),
          inputType: this.add_question.type,
          question: this.add_question.question,
          answer: "",
          isShowOrNot: true,
        });
        this.add_question.question = "";
      }
    },
    deleteQuestion(index) {
      this.additional_questions.splice(index, 1);
    },
    selectDropdown(q_index) {
      console.log("this.additional_questions", this.additional_questions);
      this.additional_questions.forEach((element, index) => {
        if (index == q_index) {
          if (element.maxSelect == element.answertags.length) {
            element.answer = "";
            return false;
          }
          this.additional_questions[q_index].dropdown.forEach(
            (drop, index2) => {
              if (element.answer == index2) {
                element.answertags.push(drop);
                element.dropdown.splice(element.answer, 1);
                element.answer = "";
              }
            }
          );
        }
      });
      console.log("this.additional_questions 2--", this.additional_questions);
    },
    selectDropdownDelete(item, q_index) {
      console.log("item", item, q_index);
      this.additional_questions.forEach((element, index) => {
        if (index == q_index) {
          this.additional_questions[q_index].answertags.forEach(
            (drop, index2) => {
              if (item.name == drop.name) {
                element.dropdown.push(drop);
                element.answertags.splice(index2, 1);
                element.answer = "";
              }
            }
          );
        }
      });
    },
    optionsDropdownDelete(item, q_index) {
      console.log("item", item, q_index);
      this.additional_questions.forEach((element, index) => {
        if (index == q_index) {
          this.additional_questions[q_index].dropdown.forEach(
            (drop, index2) => {
              if (item.name == drop.name) {
                element.dropdown.splice(index2, 1);
                element.answer = "";
              }
            }
          );
          this.additional_questions[q_index].answertags.forEach(
            (drop, index2) => {
              if (item.name == drop.name) {
                element.answertags.splice(index2, 1);
                element.answer = "";
              }
            }
          );
        }
      });
    },
    sendPreview() {
      var check = false;
      console.log('this.addBidders',this.addBidders);
      const bidders = []
      const local_additional_questions = []
      this.addBidders.forEach((element) => {
        if (
          element.present_to_customer == true ||
          element.present_to_customer == "true"
        ) {
          check = true;
          bidders.push(element)
        }
      });
      console.log('this.additional_questions',this.additional_questions);
      this.additional_questions.forEach((element) => {
        if (
          element.isShowOrNot == true ||
          element.isShowOrNot == "true"
        ) {
          local_additional_questions.push(element)
        }
      });
      // this.addBidders_Outside.forEach((element) => {
      //   if (
      //     element.present_to_customer == true ||
      //     element.present_to_customer == "true"
      //   ) {
      //     check = true;
      //     bidders.push(element)
      //   }
      // });
      // console.log("bidders",bidders);

      localStorage.setItem(
        "BidderForQuotePreview",
        JSON.stringify(bidders)
      );
      localStorage.setItem(
        "Additional_Questions_ForQuotePreview",
        JSON.stringify(local_additional_questions)
      );
      // localStorage.setItem(
      //   "BidderForQuotePreview",
      //   JSON.stringify(this.addBidders)
      // );
      this.v$.opportunity_id.$touch();
      if (!this.v$.opportunity_id.$invalid) {
        if (check) {
          this.$router.push({
            name: "Quote Presentation Preview",
            params: {
              id: this.state.opportunity_id,
              admin: 1,
              // target: "_blanck",
            },
          });
          console.log("check", check);
        } else {
          this.AddToast(
            this.lan.Please_select_Present_to_customer,
            " Information ",
            "info_1"
          );
          return;
        }
      } else {
        this.AddToast(
          this.lan.First_save_this_opportunity_then_after_send_invitation,
          this.lan.First_Save_opportunity,
          "info_1"
        );
        return;
      }
    },

    dragStart(index) {
      // Set the data being dragged
      event.dataTransfer.setData("text/plain", index);
    },
    dragOver(event) {
      // Prevent default behavior to allow dropping
      event.preventDefault();
    },
    drop(index, text) {
      // Get the index of the dragged item
      const draggedIndex = event.dataTransfer.getData("text/plain");
      var draggedItem;
      if (text == "accomodation") {
        draggedItem = this.addAcomdation[draggedIndex];
        this.addAcomdation.splice(draggedIndex, 1);
        this.addAcomdation.splice(index, 0, draggedItem);
        // Rearrange the data array
      } else if (text == "meals") {
        draggedItem = this.addMeals[draggedIndex];
        this.addMeals.splice(draggedIndex, 1);
        this.addMeals.splice(index, 0, draggedItem);
      } else if (text == "conference") {
        draggedItem = this.addConferenceRoom[draggedIndex];
        this.addConferenceRoom.splice(draggedIndex, 1);
        this.addConferenceRoom.splice(index, 0, draggedItem);
      } else if (text == "other") {
        draggedItem = this.addOtherProduct[draggedIndex];
        this.addOtherProduct.splice(draggedIndex, 1);
        this.addOtherProduct.splice(index, 0, draggedItem);
      } else if (text == "question") {
        draggedItem = this.additional_questions[draggedIndex];
        this.additional_questions.splice(draggedIndex, 1);
        this.additional_questions.splice(index, 0, draggedItem);
      } else if (text == "bidder") {
        console.log('draggedIndex', draggedIndex);
        draggedItem = this.addBidders[draggedIndex];
        console.log('draggedItem', draggedItem);
         // Remove the dragged item from the array
        this.addBidders.splice(draggedIndex, 1);
        // Insert the dragged item at the drop index
        this.addBidders.splice(index, 0, draggedItem);
        // Update the index_priority of other bidders
        this.addBidders.forEach((bidder, i) => {
          console.log('bidder index--',i);
          bidder.index_priority = i + 1;
          // if (i < index) {
          // } else if (i > index) {
          //   bidder.index_priority = i + 2;
          // }
        });
        this.isBiddersIndexChanged.isIndexChange = true;
        console.log('this.addBidders--*--', this.addBidders);
        
      }
    },
    setBiddersIndexPriority(){
      // change-priority-index
      // data-priority-wise
      var newArrayOfBidders = []
      var newIndex = 0
      var isBidder = true
      this.addBidders.forEach((item, index)=>{
        if(item.bidder_id){
          newIndex = newIndex+1
          newArrayOfBidders.push({
            bidder_id: item.bidder_id,
            index_priority : newIndex,
            opportunity_id : item.opportunity_id
          })
          console.log('newIndex - ', newIndex);
        }else{
          isBidder = false
        }
      })
      
      if(!isBidder ? confirm('Are you sure want to save changes?') : true){
        this.isBiddersIndexChanged.loader = true;
        var formData = new FormData();
        formData.append("data-priority-wise",JSON.stringify(newArrayOfBidders));
        
        console.log('newArrayOfBidders--',newArrayOfBidders);
        // return
        axios
          .post(
            axios.defaults.baseURL + "change-priority-index",
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            console.log("response", response);
            this.isBiddersIndexChanged.isIndexChange = false;
            this.isBiddersIndexChanged.loader = false;
            this.$store.dispatch("checkErrorAndSendToast", [response,'Success']);
          })
          .catch((err) => {
            console.log("err", err);
            this.isBiddersIndexChanged.isIndexChange = false;
            this.isBiddersIndexChanged.loader = false;
            this.$store.dispatch("checkErrorAndSendToast", [
              err.response,
              "error",
            ]);
          });
      }
    },

    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },

    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({
        name: "Edit Quote",
        params: { id: id },
        target: "_blanck",
      });
    },
  },
};
</script>
<style scoped>
.topCaret {
  font-size: 20px;
}
.mainAccordian {
  border: 1px solid #ccc;
}
.tick_icon {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 25px;
}
.question_to_partner .drag {
  border-left: 1px solid rgb(222, 226, 230);
  border-bottom: 1px solid rgb(222, 226, 230);
}
@media screen and (max-width: 576px) {
  .add-btn{
    padding: 6px 16px;
  }
  .c-btn{
    padding: 10px;
    height: auto;
  }
  .lower-btns-div{
    display: block !important;
  }
}
</style>
